import React, { useEffect } from "react";
import { observer } from "mobx-react";
import notify from "@app/components/notify/index";

import Input from "./input";
import Comment from "./comment";

import { Tabs, Select } from "antd";

import { useLocation } from "react-router-dom";
import qs from "qs";

import "./style/comments.scoped.scss";
import { CommentStatus } from "@app/constants";

const ALL_TAB = "all";
const OPEN_TAB = "open";
const RESOLVED_TAB = "resolved";

const Comments = observer(({ state, noItemsContent, showControls = true, onCommentFocus }) => {
    let params = qs.parse(useLocation().search, { ignoreQueryPrefix: true });
    let activeTab = state.commentsTab || OPEN_TAB;

    if (params?.resolvedcomments === "true") {
        activeTab = RESOLVED_TAB;
    } else if (params?.pendingcomments === "true") {
        activeTab = OPEN_TAB;
    }

    const [activeKey, setActiveKey] = React.useState(activeTab);
    const [userFilter, setUserFilter] = React.useState("all");
    const [usersList, setUsers] = React.useState([]);

    const comments = state.comments
        .filter((comment) => {
            if (activeKey === OPEN_TAB) {
                return comment.status === CommentStatus.PENDING;
            } else if (activeKey === RESOLVED_TAB) {
                return comment.status === CommentStatus.RESOLVED;
            }

            return true;
        })
        .filter((comment) => {
            if (userFilter && userFilter !== "all") {
                return comment.author._id === userFilter;
            }

            return true;
        });

    const switchTab = (key) => {
        setActiveKey(key);
        if (state.onCommentsTabSwitch) {
            state.onCommentsTabSwitch(key);
        }
    };

    useEffect(() => {
        const userMap = {};
        const users = state.comments
            .map((comment) => {
                return {
                    label: comment.author.fullName,
                    value: comment.author._id,
                };
            })
            .filter((user) => {
                if (userMap[user.value]) {
                    return false;
                }

                userMap[user.value] = true;
                return true;
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });

        setUsers(users);
    }, [state.comments]);

    const save = async (data) => {
        try {
            await state.saveComment(data);
            return true;
        } catch (ex) {
            notify.error("Error saving the comment");
        }
    };

    const onUserFilter = (user) => {
        setUserFilter(user);
    };

    return (
        <div className="comments sidebar">
            <Tabs size="small" destroyInactiveTabPane activeKey={activeKey} onTabClick={switchTab}>
                <Tabs.TabPane tab="Open" key={OPEN_TAB}>
                    <CommentList
                        state={state}
                        comments={comments}
                        userFilter={userFilter}
                        users={usersList}
                        onUpdate={save}
                        noContent={noItemsContent}
                        onCommentFocus={onCommentFocus}
                        onUserFilter={onUserFilter}
                    />
                </Tabs.TabPane>

                <Tabs.TabPane tab="Resolved" key={RESOLVED_TAB}>
                    <CommentList
                        state={state}
                        comments={comments}
                        userFilter={userFilter}
                        users={usersList}
                        noContent={noItemsContent}
                        onUpdate={save}
                        onCommentFocus={onCommentFocus}
                        onUserFilter={onUserFilter}
                    />
                </Tabs.TabPane>

                <Tabs.TabPane tab="All" key={ALL_TAB}>
                    <CommentList
                        state={state}
                        comments={comments}
                        userFilter={userFilter}
                        users={usersList}
                        onUpdate={save}
                        noContent={noItemsContent}
                        onCommentFocus={onCommentFocus}
                        onUserFilter={onUserFilter}
                    />
                </Tabs.TabPane>
            </Tabs>
            {showControls && (
                <Input
                    className="controls"
                    onSave={(value) => save({ text: value })}
                    showHint={true}
                />
            )}
        </div>
    );
});

const CommentList = observer(
    ({ comments, state, userFilter, users, noContent, onUpdate, onCommentFocus, onUserFilter }) => {
        const userOptions = [{ value: "all", label: "All users" }, ...users];

        /**
         * Delete a comment
         */
        const remove = async (comment) => {
            try {
                await state.removeComment(comment);
                return true;
            } catch (ex) {
                console.error(ex);
                notify.error("Error removing the comment");
            }
        };

        /**
         * Resolve a comment
         */
        const resolve = async (data) => {
            try {
                await state.resolveComment(data);
            } catch (ex) {
                notify.error("Error resolving the comment");
            }
        };

        /**
         * unresolve a comment
         */
        const unResolve = async (comment) => {
            try {
                await state.unResolveComment(comment);
            } catch (ex) {
                notify.error("Error marking the comment as pending");
            }
        };

        const addReply = async (data) => {
            await state.addReply(data);
            return true;
        };

        const updateReply = async (data) => {
            await state.updateReply(data);
        };

        const removeReply = async (data) => {
            await state.removeReply(data);
        };

        const focus = async (commentId, sectionId) => {
            if (state.focus) {
                state.focus(commentId);
            }

            if (onCommentFocus) {
                onCommentFocus(commentId, sectionId);
            }
        };

        return (
            <div className="list">
                {users.length ? (
                    <Select
                        placeholder="Show comments"
                        className="select"
                        value={userFilter}
                        options={userOptions}
                        onSelect={onUserFilter}
                    />
                ) : null}

                {comments?.map((comment) => {
                    return (
                        <Comment
                            comment={comment}
                            key={comment._id}
                            focused={state.focusedComment === comment._id}
                            onRemove={() => remove(comment)}
                            onUpdate={onUpdate}
                            onResolve={() => resolve(comment)}
                            onUnResolve={() => unResolve(comment)}
                            onAddReply={addReply}
                            onUpdateReply={updateReply}
                            onRemoveReply={removeReply}
                            onFocus={focus}
                        />
                    );
                })}

                {state.loaded && comments?.length === 0 && noContent}
            </div>
        );
    },
);

export default Comments;
