import React from "react";
import { observer } from "mobx-react";
import { LinkOutlined } from "@ant-design/icons";

import { ReportDocumentSection } from "@app/constants";

import BubbleItemContainer from "./bubble-item-container";

const CaptisLinkBubble = observer(({ isInLandscape, index, zIndex, mouseIn, metadata, editor }) => {
    if (!editor) {
        return;
    }

    const openLink = async () => {
        let href;

        if (metadata.type === ReportDocumentSection.CAPTIS_LINK.TYPE.SOURCE_DOCUMENT) {
            href = editor.commands.buildSourceDocumentHref(metadata);
        }

        if (href) {
            window.open(href, metadata.target || "_blank").focus();
        }
    };

    return (
        <BubbleItemContainer
            onClick={openLink}
            isInLandscape={isInLandscape}
            index={index}
            zIndex={zIndex}
            mouseIn={mouseIn}
            classNames={{ "captis-link-bubble": true }}
        >
            <LinkOutlined />
        </BubbleItemContainer>
    );
});

export default CaptisLinkBubble;
