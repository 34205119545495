import React from "react";
import { observer } from "mobx-react";
import format from "@app/lib/format";
import { Button } from "antd";

import Group from "../../group";
import "./style.scoped.scss";

const DocumentStyles = observer(({ config, readonly, onEdit }) => {
    const style = config.reportDocuments.style ?? {};

    return (
        <Group title="Document Style" onEdit={!readonly && onEdit}>
            <div className="summary">
                <div className="entry template">
                    {!style.file && (
                        <>
                            <label>Default system template:</label>
                            <a
                                href={format.file("defaultStyleTemplate")}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button style={{ marginRight: `15px` }}>Download</Button>
                            </a>
                        </>
                    )}
                    {style.file && (
                        <>
                            <label>Custom style template:</label>
                            <a href={format.file(style.file)} target="_blank" rel="noreferrer">
                                <Button style={{ marginRight: `15px` }}>Download</Button>
                            </a>
                        </>
                    )}
                </div>
                <div className="entry">
                    <label>Section Numbers:</label>
                    <em>
                        {style.sectionNumbering === "noDot"
                            ? "No dot after the number"
                            : "With a dot after the number"}
                    </em>
                </div>
                <div className="entry">
                    <label>Caption Numbering:</label>
                    <em>{style.captionIndex === "global" ? "Global" : "Per Section"}</em>
                </div>
                <div className="entry">
                    <label>Table/Figure Reference:</label>
                    <em>
                        {style.captionRef === "index" && "Index Only"}
                        {style.captionRef === "title" && "Title Only"}
                        {style.captionRef === "full" && "Full Caption"}
                    </em>
                </div>
                <div className="entry">
                    <label>Section Reference:</label>
                    <em>
                        {style.sectionRef === "index" && "Index Only"}
                        {style.sectionRef === "title" && "Title Only"}
                        {style.sectionRef === "full" && "Full Section"}
                    </em>
                </div>
                <div className="entry">
                    <label>TOC Levels:</label>
                    <em>1 - {style.tocLevel}</em>
                </div>
            </div>
        </Group>
    );
});

export default DocumentStyles;
