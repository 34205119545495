import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import EmptyList from "@app/components/list/empty";
import confirm from "@app/components/confirm";

import EditModal from "@app/components/dictionary/edit";
import EntryCard from "@app/components/dictionary/card";

import "./style/list.scoped.scss";
import state from "@app/state/store/report/dictionary/list";

const List = observer((props) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [entry, setEntry] = useState(undefined);

    useEffect(() => {
        state.load();
    }, []);

    /**
     * Handle edit event
     */
    const edit = (entry) => {
        setEntry(entry);
        setShowEditModal(true);
    };

    /**
     * Handle save event
     */
    const save = async (entry) => {
        if (!state.validateSave(entry)) {
            return;
        }

        state.save(entry);

        setShowEditModal(false);
        setEntry(undefined);
    };

    /**
     * Handle delete event
     */
    const remove = async (entry) => {
        let proceed = await confirm("Are you sure you want to remove this entry?");
        if (proceed) {
            await state.remove(entry._id);
        }
    };

    return (
        <Page className="users">
            <Page.Header>
                <Page.Header.Left shrink>
                    <Page.Title>Dictionary</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setShowEditModal(true);
                        }}
                    >
                        Add Entry
                    </Button>
                </Page.Header.Right>
            </Page.Header>
            <Filter.Header filter={state.filter} />
            <Page.Layout>
                <Page.Body filter={state.filter}>
                    <EmptyList
                        title="No Results Found"
                        show={state.list.length === 0 && !state.loading}
                    >
                        You can use the <b>Add</b> button to add more
                    </EmptyList>

                    {state.list.map((entry) => {
                        return (
                            <EntryCard
                                key={entry._id}
                                entry={entry}
                                onEdit={edit}
                                onRemove={remove}
                            />
                        );
                    })}
                </Page.Body>
            </Page.Layout>

            {showEditModal && (
                <EditModal
                    entry={entry}
                    onChange={save}
                    onCancel={() => {
                        setEntry(undefined);
                        setShowEditModal(false);
                    }}
                />
            )}
        </Page>
    );
});

export default List;
