import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import ReportDocumentsGroup from "@app/components/setup/report-documents/group";
import notify from "@app/components/notify";

import store from "@app/state/store/report-document/report-documents";

const ConfigurationReportDocuments = observer(({ className, onCopy, onPaste, copied }) => {
    const { id } = useParams();

    useEffect(() => {
        store.setClientId(id);
        store.loadReportDocuments();

        return () => {
            store.reset();
        };
    }, [id]);

    if (!store.reportDocuments) {
        return;
    }

    const update = async (type, entry, args = {}) => {
        try {
            await store.updateReportDocument(type, entry);
            await store.loadReportDocuments();
        } catch (ex) {
            const message = args.error || "Error saving the report document configuration";
            notify.error(message);
        }
    };

    return (
        <ReportDocumentsGroup
            list={store.reportDocuments}
            title="Document Templates"
            onChange={(type, entry) => {
                update(type, { client: id, ...entry });
            }}
            onCopy={onCopy}
            onPaste={async () => {
                await onPaste();
                await store.loadReportDocuments();
            }}
            copied={copied}
            className={className}
        />
    );
});

export default ConfigurationReportDocuments;
