import BaseStore from "../base";
import { action, computed } from "mobx";
import http from "@app/lib/http";
import { events } from "@app/lib/store";
import ReportDocumentSection from "@app/state/model/report-document/report-document-section";
import User from "@app/state/model/report-document/user";
import notify from "@app/components/notify";
import { localStore } from "@app/lib/storage";

import report from "../report";
import commentStore from "./comment";
import reportDocumentStore from "./report-documents";

import { SCHEMA_VERSION, ReportDocumentStatus } from "@app/constants";

function cleanCommentMarks(obj) {
    if (Array.isArray(obj)) {
        return obj.map(cleanCommentMarks);
    } else if (typeof obj === "object" && obj !== null) {
        if (obj.type === "commentMark") {
            return {
                type: "commentMark",
                attrs: {
                    comment: obj.attrs.comment,
                },
            };
        } else {
            let newObj = {};
            for (let key in obj) {
                newObj[key] = cleanCommentMarks(obj[key]);
            }
            return newObj;
        }
    } else {
        return obj;
    }
}

function extractSectionCommentIds(contentObj, ids = []) {
    if (Array.isArray(contentObj)) {
        contentObj.forEach((obj) => extractSectionCommentIds(obj, ids));
    } else if (typeof contentObj === "object" && contentObj !== null) {
        if (contentObj.type === "commentMark") {
            const splitIds = contentObj.attrs.comment.split(",");

            splitIds.forEach((id) => {
                if (!ids.includes(id)) {
                    ids.push(id);
                }
            });
        } else if (contentObj?.attrs?.comment) {
            const splitIds = contentObj.attrs.comment.split(",");

            splitIds.forEach((id) => {
                if (!ids.includes(id)) {
                    ids.push(id);
                }
            });
        } else {
            for (let key in contentObj) {
                extractSectionCommentIds(contentObj[key], ids);
            }
        }
    }

    return ids;
}

const isNetworkError = (ex = {}) => {
    const { code, message, response } = ex;

    if (response === undefined && (code === "ECONNABORTED" || message === "Network Error")) {
        return true;
    }
    return false;
};

const isDivergedError = (ex = {}) => {
    const { code, message } = ex;

    if (code === 409 || message.includes(409)) {
        return true;
    }
    return false;
};

export class ReportDocumentSections extends BaseStore {
    observable() {
        return {
            section: null,
            sections: [],
            saved: true,
            saving: false,
            divergedSectionIds: [],
            lastSave: null,
            localSectionIds: [],
        };
    }

    contentVersions = {};

    handleErrorSaving(sectionId, content, ex = {}) {
        this.saved = false;
        this.saving = false;

        if (isNetworkError(ex)) {
            this.saveLocal(sectionId, content);
        } else if (isDivergedError(ex)) {
            this.divergedSectionIds.push(sectionId);

            notify.error(ex.response?.data?.error || "Cannot save section content");
        } else {
            notify.error(ex.response?.data?.error || "Cannot save section content");
        }
    }

    /**
     * Return the project id of the currently loaded project
     */
    @computed get project() {
        return report.id || this.section?.project;
    }

    @action
    async get(sectionId, save = true, projectId) {
        let project = projectId || this.project;

        try {
            const { data } = await http.get(
                `/project/${project}/report-document-sections/${sectionId}`,
            );

            const section = new ReportDocumentSection(data);

            if (save) {
                this.section = section;
                this.saved = true;
                this.lastSave = null;
            }

            return section;
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot get toc section");
        }
    }

    @action
    async refetch({ sectionId, projectId }) {
        let project = projectId || this.project;

        try {
            const { data } = await http.get(
                `/project/${project}/report-document-sections/${sectionId}`,
            );

            const section = new ReportDocumentSection(data);
            this.contentVersions[sectionId] = data.content_version;

            const idx = this.sections.findIndex((section) => section._id === sectionId);
            this.sections[idx] = section;

            return section;
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot get toc section");
        }
    }

    @action
    async delete(id) {
        try {
            await http.delete(`/project/${this.project}/report-document-sections/${id}`);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot delete toc section");
        }
    }

    @action
    async create(section) {
        if (!section) {
            return;
        }

        try {
            let { data } = await http.put(`/project/${this.project}/report-document-sections`, {
                section,
            });
            this.section = new ReportDocumentSection(data);
            return this.section;
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot create toc section");
        }
    }

    @action
    async update(id, section) {
        if (!section) {
            return;
        }

        try {
            this.saving = true;
            let { data } = await http.post(
                `/project/${this.project}/report-document-sections/${id}`,
                {
                    section,
                },
            );

            this.section = new ReportDocumentSection(data);
            this.saving = false;

            events.emit("reportDocument.toc.reload");
            events.emit("reportDocumentList.reload");
            events.emit("reportDocument.viewer.reload.metaData");
            return this.section;
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot save toc section");
        }
    }

    saveToLocalStorage(sectionId, section) {
        localStore.set(`report.document.section.${sectionId}`, section);
    }

    getFromLocalStorage(sectionId) {
        return localStore.get(`report.document.section.${sectionId}`);
    }

    removeFromLocalStorage(sectionId) {
        localStore.remove(`report.document.section.${sectionId}`);
    }

    async saveRemote({ sectionId, content, projectId, contentVersion, overwrite }) {
        this.saving = true;
        const { data } = await http.post(
            `/project/${projectId}/report-document-sections/${sectionId}/content`,
            {
                schemaVersion: SCHEMA_VERSION,
                content,
                contentVersion,
                overwrite,
            },
        );

        this.removeFromLocalStorage(sectionId);

        this.contentVersions[sectionId] = data.content_version;

        this.saved = true;
        this.saving = false;
        this.lastSave = new Date();

        if (this.localSectionIds.includes(sectionId)) {
            this.localSectionIds = this.localSectionIds.filter((id) => id !== sectionId);
        }

        if (this.divergedSectionIds.includes(sectionId)) {
            this.divergedSectionIds = this.divergedSectionIds.filter((id) => id !== sectionId);
        }

        await commentStore.updateParsedComments({ sectionId });

        return new ReportDocumentSection(data);
    }

    saveLocal(sectionId, content) {
        const section = this.sections.find(({ _id }) => _id === sectionId);

        this.saveToLocalStorage(sectionId, {
            ...section,
            content,
            content_version:
                section.content_version > this.contentVersions[section._id]
                    ? section.content_version
                    : this.contentVersions[section._id],
        });

        if (!this.localSectionIds.includes(sectionId)) {
            this.localSectionIds.push(sectionId);
        }

        this.saved = true;
        this.saving = false;
        this.lastSave = new Date();
    }

    @action.bound
    discardLocal(sectionId) {
        this.removeFromLocalStorage(sectionId);
        this.saved = false;
        this.saving = false;
        this.lastSave = null;

        this.divergedSectionIds = this.divergedSectionIds.filter((id) => id !== sectionId);
        this.localSectionIds = this.localSectionIds.filter((id) => id !== sectionId);
        window.location.reload();
    }

    // only update the relevant properties from the current section on the list
    updateCurrentSection(updatedSection) {
        const section = this.sections.find((section) => section?._id === updatedSection?._id);

        if (section) {
            if (section.status !== updatedSection.status) {
                section.status = updatedSection.status;
            }
        }
    }

    updateCurrentReportDocument(reportDocumentId) {
        const reportDocument = reportDocumentStore.reportDocuments.find(
            (r) => r._id === reportDocumentId,
        );

        if (reportDocument) {
            if (reportDocument.status === ReportDocumentStatus.NOT_STARTED) {
                reportDocument.status = ReportDocumentStatus.IN_PROGRESS;
            }
        }
    }

    refreshToc(section, updatedSection) {
        if (section?.status !== updatedSection?.status) {
            events.emit("reportDocument.toc.reload");
        }
    }

    @action
    async saveContent(
        sectionId,
        rawContent,
        { projectId, documentId, overwrite, section, backgroundSave = false },
    ) {
        if (this.saving) {
            return;
        }

        if (this.saved && !this.localSectionIds.includes(sectionId) && !backgroundSave) {
            return;
        }

        const content = cleanCommentMarks(rawContent);
        const commentIds = extractSectionCommentIds(content);

        commentStore.setCommentIdsForSection(sectionId, commentIds);

        try {
            const updatedSection = await this.saveRemote({
                sectionId,
                content,
                projectId,
                overwrite,
                contentVersion: this.contentVersions[sectionId],
            });

            this.updateCurrentSection(updatedSection);
            if (section) {
                this.updateCurrentReportDocument(section.reportDocumentId);
                this.refreshToc(section, updatedSection);
            }
        } catch (ex) {
            if (!backgroundSave) {
                this.handleErrorSaving(sectionId, content, ex);
            }
        }
    }

    @action.bound
    async syncChangesForSection(sectionId) {
        const localSection = this.getFromLocalStorage(sectionId);
        const remoteSection = await this.get(sectionId);

        if (!localSection) {
            this.removeFromLocalStorage(sectionId);
            this.contentVersions[sectionId] = remoteSection.content_version;

            return new ReportDocumentSection(remoteSection);
        }

        if (!localSection.content_version) {
            localSection.content_version = 0;
        }

        // not able to fetch (e.g. network error)
        if (!remoteSection) {
            return new ReportDocumentSection(localSection);
        }

        if (localSection.content_version < remoteSection.content_version) {
            this.divergedSectionIds.push(sectionId);

            return new ReportDocumentSection(localSection);
        }

        try {
            return await this.saveRemote({
                sectionId,
                content: localSection.content,
                projectId: this.project,
                contentVersion: localSection.content_version,
                overwrite: false,
            });
        } catch (ex) {
            this.handleErrorSaving(sectionId, localSection.content, ex);

            return new ReportDocumentSection(localSection);
        }
    }

    @action
    async saveTemplateContent(id, content, documentId) {
        try {
            this.saving = true;
            await http.post(
                `/client/${reportDocumentStore.clientId}/template/${documentId}/template-sections/${id}/content`,
                {
                    schemaVersion: SCHEMA_VERSION,
                    content: cleanCommentMarks(content),
                },
            );

            this.saved = true;
            this.saving = false;
            this.lastSave = new Date();
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot save section content");
        }
    }

    @action
    async isLocked(id) {
        try {
            const { data } = await http.get(
                `/project/${this.project}/report-document-sections/${id}/lock`,
            );

            return new User(data);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot check lock");
        }
    }

    @action
    async updateLock(id) {
        try {
            await http.post(`/project/${this.project}/report-document-sections/${id}/lock/update`);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot update lock");
        }
    }

    @action
    async resetLock(id) {
        try {
            await http.post(`/project/${this.project}/report-document-sections/${id}/lock/reset`);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot reset lock");
        }
    }

    @action
    async requestLock(id) {
        try {
            await http.post(`/project/${this.project}/report-document-sections/${id}/lock/request`);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot lock section");
        }
    }

    @action
    async releaseLock(id, projectId) {
        const project = projectId || this.project;

        try {
            await http.post(`/project/${project}/report-document-sections/${id}/lock/release`);
            const section = this.sections.find((section) => section._id === id);
            if (section) {
                section.lockedBy = undefined;
            }
        } catch (ex) {
            if (!isNetworkError(ex)) {
                notify.error(ex.response?.data?.error || "Cannot release lock");
            }
        }
    }

    @action
    async denyLock(id) {
        try {
            await http.post(`/project/${this.project}/report-document-sections/${id}/lock/deny`);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Cannot deny lock");
        }
    }

    @action.bound
    async loadDetails(sectionId) {
        const localSection = this.getFromLocalStorage(sectionId);

        if (localSection) {
            this.localSectionIds.push(sectionId);
            this.contentVersions[sectionId] = localSection.content_version;

            return await this.syncChangesForSection(sectionId, this.project);
        } else {
            const { data } = await http.get(
                `/project/${this.project}/report-document-sections/${sectionId}`,
            );
            this.contentVersions[sectionId] = data.content_version;

            const commentIds = extractSectionCommentIds(data.content);
            commentStore.setCommentIdsForSection(sectionId, commentIds);

            return new ReportDocumentSection(data);
        }
    }

    async loadTemplateDetails(id, documentId) {
        const { data } = await http.get(
            `/client/${reportDocumentStore.clientId}/template/${documentId}/template-sections/${id}`,
        );

        return new ReportDocumentSection(data);
    }

    @action
    async reviewSection(id) {
        try {
            const { data } = await http.post(
                `/project/${this.project}/report-document-sections/${id}/review`,
            );

            events.emit("reportDocument.toc.reload");
            events.emit("reportDocumentList.reload");
            events.emit("reportDocument.viewer.reload.metaData");
            return new ReportDocumentSection(data);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "Error reviewing section");
        }
    }
}

const reportDocumentSectionsStore = new ReportDocumentSections();

export default reportDocumentSectionsStore;
