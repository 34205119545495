import React from "react";
import { observer } from "mobx-react";
import { groupBy, maxBy } from "lodash";

import "./list.scoped.scss";

import Version from "./version";

import { Collapse, CollapseProvider } from "@app/components/collapse";

import Title from "./title";

const versionFilter = (
    { _id, sectionIds = [], createdBy } = {},
    { sectionId, latestVersionId, userId },
) => {
    if (_id === latestVersionId) {
        return false;
    }

    let result = true;

    if (sectionId) {
        result = sectionIds.includes(sectionId);
    }

    if (result !== false && userId) {
        result = createdBy?._id === userId;
    }

    return result;
};

const List = observer(({ versions, sectionId, userId, state, sectionMap }) => {
    const latestVersion = maxBy(versions, "updatedAt");

    const groupedVersions = groupBy(
        versions.filter((v) =>
            versionFilter(v, { sectionId, latestVersionId: latestVersion._id, userId }),
        ),
        (v) => {
            const date = new Date(v.updatedAt);
            return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        },
    );

    const keys = Object.keys(groupedVersions);

    return (
        <CollapseProvider>
            <ul className="version-list">
                {latestVersion && (
                    <Version
                        versionId={latestVersion._id}
                        title={latestVersion.title}
                        type={latestVersion.type}
                        updatedAt={latestVersion.updatedAt}
                        createdBy={latestVersion.createdBy?.fullName}
                        sectionIds={latestVersion.sectionIds}
                        sectionMap={sectionMap}
                        selectedSectionId={latestVersion.sectionId}
                        state={state}
                        isLatestVersion
                    />
                )}

                {keys.map((key) => {
                    const versions = groupedVersions[key];

                    if (versions.length > 1) {
                        return (
                            <Collapse key={key} id={key} title={<Title updatedAt={key} hideTime />}>
                                {versions.map((version) => (
                                    <Version
                                        key={version._id}
                                        versionId={version._id}
                                        title={version.title}
                                        type={version.type}
                                        updatedAt={version.updatedAt}
                                        createdBy={version.createdBy?.fullName}
                                        sectionIds={version.sectionIds}
                                        sectionMap={sectionMap}
                                        selectedSectionId={sectionId}
                                        state={state}
                                        grouped={true}
                                    />
                                ))}
                            </Collapse>
                        );
                    }

                    const version = versions[0];

                    return (
                        <Version
                            key={version._id}
                            versionId={version._id}
                            title={version.title}
                            type={version.type}
                            updatedAt={version.updatedAt}
                            createdBy={version.createdBy?.fullName}
                            sectionIds={version.sectionIds}
                            sectionMap={sectionMap}
                            selectedSectionId={sectionId}
                        />
                    );
                })}
            </ul>
        </CollapseProvider>
    );
});

export default List;
