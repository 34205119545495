import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Button, Select } from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import Wand from "@app/assets/icons/wand";
import AdditionalDataField from "./additionalDataField";
import { events } from "@app/lib/store";

import articleStore from "@app/state/store/report/article";
import reportStore from "@app/state/store/report";
import session from "@app/state/store/session";
import applicationStore from "@app/state/store/application";

import "../style/icon.scoped.scss";
import "./style/additionalData.scoped.scss";

const AdditionalData = observer(() => {
    const { type } = useParams();

    const filterStoreName = `${type}_additionalData_filter`;

    const article = articleStore.article;
    const config = reportStore.config?.[type];
    let additionalDataMap = {};
    const readonly = !session.can("article.update") || reportStore.readOnly;
    const processing = article.processing?.additionalData;

    const [additionalDataItems, setAdditionalDataItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showAI, setShowAI] = useState(false);

    const showAIValue = (additionalData) => {
        let result = false;
        if (applicationStore?.enableAI && article.file && !processing) {
            // if there is a field with question and no suggestion
            const needsSuggestionField = additionalData.find(
                (item) => item.ai?.question && !item.ai?.suggestion,
            );
            if (needsSuggestionField) {
                result = true;
            }
        }

        return result;
    };

    const getMergedAdditionalData = () => {
        if (article?.additionalData?.length > 0) {
            article.additionalData.forEach((additionalData) => {
                additionalDataMap[additionalData._id] = additionalData;
            });
        }

        const items = config?.additionalData?.map((item) => {
            const dataMapItem = additionalDataMap?.[item._id];
            return {
                ...item,
                ai: {
                    ...item?.ai,
                    ...dataMapItem?.ai,
                },
                value: dataMapItem?.value ?? "",
            };
        });

        return items || [];
    };

    const setup = () => {
        const mergedItems = getMergedAdditionalData();
        setShowAI(showAIValue(mergedItems));

        // Tries to pre-populate the filter from the store
        if (reportStore.temp?.[filterStoreName]?.length > 0) {
            const selectedItemsState = reportStore.temp?.[filterStoreName];
            setSelectedItems(selectedItemsState);
            const filteredItems = mergedItems.filter((item) =>
                selectedItemsState.includes(item.title),
            );
            setAdditionalDataItems(filteredItems);
        } else {
            setSelectedItems([]);
            setAdditionalDataItems(mergedItems);
        }
    };

    useEffect(() => {
        setup();
        events.on("article.update", setup);

        return () => {
            events.removeListener("article.update", setup);
        };
    }, []);

    const saveAdditionalData = async (additionalData) => {
        await articleStore.updateAdditionalData({ additionalData, type });
    };

    const generateSuggestions = async () => {
        if (processing) {
            return;
        }

        articleStore.generateSuggestionsForAdditionalData({ type });
        setShowAI(false);
    };

    const selectOptions = getMergedAdditionalData().map((item) => {
        return {
            _id: item._id,
            label: item.title,
            value: item.title,
        };
    });

    const handleSelectChange = (values) => {
        const mergedItems = getMergedAdditionalData();
        setSelectedItems(values);

        // populate the filter to the store
        reportStore.addToTemp(filterStoreName, values);

        if (values.length === 0) {
            setAdditionalDataItems(mergedItems);
        } else {
            const filteredItems = mergedItems.filter((item) => values.includes(item.title));
            setAdditionalDataItems(filteredItems);
        }
    };

    return (
        <div data-testid="article-sidebar-additionalData" className="container">
            {config?.additionalData?.length === 0 ? (
                <div className="emptyIconContainer">
                    <div className="icon">
                        <InfoCircleOutlined />
                    </div>

                    <div className="text">
                        There are no data extraction fields for this article.
                        <br />
                        You can add data extraction fields at the project setup.
                    </div>
                </div>
            ) : (
                <>
                    <div className="actionMenu">
                        {processing ? (
                            <Button icon={<LoadingOutlined />} type="icon">
                                <span>Processing Data Fields...</span>
                            </Button>
                        ) : (
                            showAI && (
                                <Button icon={<Wand />} type="icon" onClick={generateSuggestions}>
                                    <span>Generate Suggestions</span>
                                </Button>
                            )
                        )}
                    </div>

                    {additionalDataItems.length > 0 && (
                        <Select
                            mode="multiple"
                            allowClear
                            autoClearSearchValue={false}
                            showArrow={true}
                            className="select"
                            placeholder="Filter data fields"
                            maxTagCount={0}
                            maxTagPlaceholder={() => (
                                <span>
                                    {`Showing ${selectedItems.length} of 
                                ${selectOptions.length} fields`}
                                </span>
                            )}
                            onChange={handleSelectChange}
                            value={selectedItems}
                            options={selectOptions}
                        />
                    )}

                    {additionalDataItems.map((item) => {
                        return (
                            <AdditionalDataField
                                additionalData={item}
                                options={item.options}
                                readonly={readonly}
                                onChange={saveAdditionalData}
                                key={item._id}
                            />
                        );
                    })}
                </>
            )}
        </div>
    );
});

export default AdditionalData;
