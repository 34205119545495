import { action, computed } from "mobx";
import { v4 as uuid } from "uuid";
import events from "@app/lib/store/events";
import notify from "@app/components/notify";

import BaseStore from "../../base";
import report from "../../report";
import Document from "@app/state/model/document";
import UploadState from "@app/components/form/upload/progress/state";
import Comment from "@app/state/model/comment";
import { CommentStatus } from "@app/constants";

import http from "@app/lib/http";
import { ws } from "@app/lib/socket";

const STGR_LOAD = uuid();

export class DocumentStore extends BaseStore {
    observable() {
        return {
            document: null,
            comments: [],
            focusedComment: null,
            commentsTab: null,
            filteredComments: [],
            bookmarks: [],
            loading: false,
        };
    }

    constructor() {
        super();

        this.uploadState = new UploadState();

        ws.on("document.changed", (id) => {
            if (this.document?._id === id) {
                this.load(id, true);
            }
        });

        ws.on("document.previewStatus", ({ documentId, status, preview }) => {
            if (this.document?._id === documentId) {
                this.document.preview = preview;
                this.document.previewStatus = status;
            }
        });

        events.on("project.unload", () => {
            this.reset();
        });
    }

    @computed get project() {
        return report.id;
    }

    @computed get busy() {
        return this.loading || this.saving;
    }

    /**
     * Load a document details
     */
    @action
    async load(id, force = false) {
        // do not load the document data twice
        if (this.document?._id === id && force === false) {
            return;
        }

        this.loading = true;
        let { data } = await http.get(`/project/${this.project}/document/${id}`).stagger(STGR_LOAD);

        if (data._id) {
            this.document = new Document(data);
            await this.loadComments();
        } else {
            this.document = null;
        }

        this.loading = false;
    }

    /**
     * Update a document
     */
    @action
    async update(params) {
        if (!this.document) {
            return;
        }

        try {
            this.saving = true;

            let { data } = await http.put(
                `/project/${this.project}/document/${this.document._id}`,
                params,
            );

            if (!data?.preview && params.file) {
                data.preview = null;
            }

            this.document.set(data);
            events.emit("document.update", this.document);
        } finally {
            this.saving = false;
        }
    }

    /**
     * Upload a document
     */
    @action
    async upload(file, category) {
        const payload = {};
        payload.file = file;
        payload.project = this.project;
        payload.category = category;

        try {
            this.loading = true;

            const { data } = await http.post(`/project/${this.project}/document`, payload);
            events.emit("document.update", data);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    categoryDetails(configuredCategories) {
        const assignedCategories = configuredCategories.filter((category) =>
            this.document.categories.includes(category._id),
        );

        const assignedCategoryNames = assignedCategories.map((item) => item.name);
        return { assignedCategories, assignedCategoryNames };
    }

    @action
    async restore(documentId, history) {
        if (!documentId) {
            return;
        }

        try {
            this.loading = true;

            const { data } = await http.put(
                `/project/${this.project}/document/${documentId}/restore`,
                {
                    historyId: history._id,
                },
            );

            this.document.set(data);
            events.emit("document.update", this.document);
        } catch (ex) {
            notify.error(ex.response?.data?.error);
        } finally {
            this.loading = false;
        }
    }

    @action
    onCommentsTabSwitch(tab) {
        this.commentsTab = tab;
        this.filterComments();
    }

    @action
    filterComments() {
        let comments = [];

        if (this.commentsTab === "open") {
            comments = this.comments
                .slice()
                .filter((comment) => comment.status === CommentStatus.PENDING);
        } else if (this.commentsTab === "resolved") {
            comments = this.comments
                .slice()
                .filter((comment) => comment.status === CommentStatus.RESOLVED);
        } else {
            comments = this.comments.slice();
        }

        this.filteredComments = comments;
    }

    @action
    async loadComments(sort = "desc") {
        if (!this.document?._id) {
            return;
        }

        let { data } = await http.get(
            `/project/${this.project}/document/${this.document._id}/comments?sort=${sort}`,
        );

        this.comments = data.map((entry) => {
            return new Comment(entry);
        });

        this.commentsTab = "open";
        this.filterComments();
    }

    @action
    async saveComment(comment, sort = "desc") {
        if (!this.document?._id) {
            return;
        }

        if (comment._id) {
            const { data } = await http.put(
                `/project/${this.project}/document/${this.document._id}/comment/${comment._id}`,
                comment,
            );

            let found = this.comments.find((el) => el._id === comment._id);
            if (found) {
                found = data.text;
            }
        } else {
            const { data } = await http.post(
                `/project/${this.project}/document/${this.document._id}/comment`,
                comment,
            );

            if (sort === "desc") {
                this.comments.unshift(new Comment(data));
            } else {
                this.comments.push(new Comment(data));
            }
        }
        this.filterComments();
    }

    @action
    async removeComment(comment) {
        await http.delete(
            `/project/${this.project}/document/${this.document._id}/comment/${comment._id}`,
        );

        this.comments = this.comments.filter((el) => el._id !== comment._id);
        this.filterComments();
    }

    /**
     * Resolve a comment
     */
    @action
    async resolveComment(comment) {
        const { data } = await http.put(
            `/project/${this.project}/document/${this.document._id}/comment/${comment._id}/resolve`,
        );

        let found = this.comments.find((el) => el._id === comment._id);

        if (found) {
            found.status = data.status;
            found.resolvedBy = data.resolvedBy;
            found.resolvedOn = data.resolvedOn;
            found.replies = data.replies;

            this.filterComments();
        }
    }

    /**
     * Mark a comment as pending
     */
    @action
    async unResolveComment(comment) {
        const { data } = await http.put(
            `/project/${this.project}/document/${this.document._id}/comment/${comment._id}/unresolve`,
        );

        let found = this.comments.find((el) => el._id === comment._id);

        if (found) {
            found.status = data.status;
            found.resolvedBy = undefined;
            found.resolvedOn = undefined;
            found.replies = data.replies;
            if (!found.expanded) {
                found.expanded = true;
            }

            this.filterComments();
        }
    }

    /**
     * Add a reply to an existing comment
     */
    @action
    async addReply(params) {
        const { data } = await http.post(
            `/project/${this.project}/document/${this.document._id}/comment/${params.comment._id}/reply`,
            {
                text: params.text,
            },
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);
        comment.replies = data.replies;
    }

    /**
     * Update an existing comment's reply
     */
    @action
    async updateReply(params) {
        const { data } = await http.put(
            `/project/${this.project}/document/${this.document._id}/comment/${params.comment._id}/reply/${params.replyId}`,
            {
                reply: params.reply,
                text: params.text,
            },
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);

        comment.replies = data.replies;
    }
    /**
     * Remove an existing reply
     */
    @action
    async removeReply(params) {
        const { data } = await http.delete(
            `/project/${this.project}/document/${this.document._id}/comment/${params.comment._id}/reply/${params.replyId}`,
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);
        comment.replies = data.replies;
    }
}

export default new DocumentStore();
