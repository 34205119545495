import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { Tabs } from "antd";
import qs from "qs";
import { CommentOutlined, BookOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Page } from "@app/components/page";

import { SidebarTabKeys } from "@app/constants";

import { useSidebar } from "../../page/sidebar";
import Bookmarks from "./bookmarks";

import commentStore from "@app/state/store/report-document/comment";

import Comments from "@app/components/comments/sidebarComments";

import "./styles/sidebar.scoped.scss";
import {
    scrollToComment,
    scrollToSelection,
    scrollToSection,
} from "@app/components/report-document/editor/utils";

const ReportDocumentSidebar = observer(({ handleFail, width = 400, ...rest }) => {
    const sidebar = useSidebar(null);
    const location = useLocation();
    const history = useHistory();
    const [activeKey, setActiveKey] = useState(SidebarTabKeys.COMMENTS_TAB);

    useEffect(() => {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });
        const commentId = params.comment;
        const hasComments = commentStore.comments.length;

        if (hasComments > 0 && commentId && commentId !== commentStore.focusedComment) {
            delete params.comment;
            history.replace({
                pathname: location?.pathname,
                search: "?" + qs.stringify(params, { encodeValuesOnly: true, indices: false }),
            });

            setTimeout(async () => {
                commentStore.focus(commentId);

                scrollToComment(commentId);
                scrollToSelection(commentId);
            }, 500);
        }
    }, [location, commentStore.comments]);

    useEffect(() => {
        if (commentStore.focusedComment) {
            sidebar.show();
            sidebar.setInline(true);
        }
    }, [sidebar, commentStore.focusedComment, commentStore.comments]);

    const switchTab = (key) => {
        setActiveKey(key);
        commentStore.resetFocus();
    };

    return (
        <Page.Sidebar
            className="report-document-sidebar"
            sidebar={sidebar}
            width={width}
            padding={false}
            collapsible
            {...rest}
        >
            <Tabs
                type="card"
                activeKey={activeKey}
                defaultActiveKey={SidebarTabKeys.COMMENTS_TAB}
                onTabClick={switchTab}
                className="ant-tabs"
            >
                <Tabs.TabPane
                    tab={<CommentOutlined className="tab-icon" />}
                    key={SidebarTabKeys.COMMENTS_TAB}
                >
                    <CommentsTab />
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={<BookOutlined className="tab-icon" />}
                    key={SidebarTabKeys.BOOKMARKS_TAB}
                >
                    <Bookmarks />
                </Tabs.TabPane>
            </Tabs>
        </Page.Sidebar>
    );
});

const CommentsTab = observer(() => {
    const onCommentFocus = (commentId, sectionId) => {
        if (!sectionId) {
            scrollToSelection(commentId);
        } else {
            scrollToSection(sectionId);
        }
    };

    return (
        <Comments
            state={commentStore}
            showControls={false}
            onCommentFocus={onCommentFocus}
            noItemsContent={
                <div className="empty">
                    <div className="icon">
                        <InfoCircleOutlined />
                    </div>
                    <div className="text">
                        There are no comments available.
                        <br />
                        You can add comment on selected text using the comment button above the
                        selected text.
                        <br />
                        You can also tag and notify other users using @.
                    </div>
                </div>
            }
        />
    );
});

ReportDocumentSidebar.displayName = "ReportDocumentSidebar";

export default ReportDocumentSidebar;
