import React, { useEffect, useMemo } from "react";
import { observer, useLocalStore } from "mobx-react";
import _ from "lodash";
import { Button, Input, Select, Tooltip } from "antd";
import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import Wand from "@app/assets/icons/wand";
import classes from "classnames";
import TextInput from "./text-input";

import aiStore from "@app/state/store/ai";

import "./style/additionalData.scoped.scss";

const SAVE_TIMEOUT = 5 * 1000;

const AdditionalDataField = observer(({ additionalData, options, onChange, readonly = false }) => {
    const state = useLocalStore(() => ({
        defaultValue: additionalData?.value || "",
        changed: false,
        showAIField: false,
        suggestion: undefined,
    }));
    const type = additionalData?.type;
    let timer = null;

    useEffect(() => {
        const show =
            aiStore.isValidSuggestion(additionalData.ai?.suggestion) && !additionalData.ai.handled;
        if (show !== state.showAIField) {
            state.showAIField = show;
        }

        if (state.suggestion !== additionalData.ai.suggestion) {
            state.suggestion = additionalData.ai.suggestion;
        }
    }, [additionalData.ai.question, additionalData.ai.suggestion, additionalData.ai.handled]);
    /**
     * Handle changes to text input. Schedules a text save event every few seconds.
     */
    const textChanged = (value) => {
        state.changed = true;

        state.defaultValue = value;
        additionalData.value = value;
        scheduleSaveText();
    };

    /**
     * Schedule a text save action after a set amount of time.
     */
    const scheduleSaveText = () => {
        clearTimeout(timer);
        timer = setTimeout(saveText, SAVE_TIMEOUT);
    };

    /**
     * Immediately emit a save event
     */
    const saveText = () => {
        if (state.changed) {
            state.changed = false;
            clearTimeout(timer);

            onChange && onChange(additionalData);
        }
    };

    const setSelectedValue = (value) => {
        // update the default value
        state.defaultValue = value;
        additionalData.value = value;

        onChange && onChange(additionalData);
    };

    const handleSuggestion = _.debounce((value) => {
        additionalData.ai.handled = true;
        setSelectedValue(value);
        state.showAIField = false;
    }, 300);

    const handleOnBlurSuggestion = () => {
        if (!additionalData.ai.handled) {
            if (additionalData.ai.suggestion !== state.suggestion) {
                handleSuggestion(state.suggestion);
            }
        }
    };

    const AITextField = useMemo(() => {
        return (
            <div className="aiFieldField">
                <Input.TextArea
                    value={state.suggestion}
                    autoSize={{ minRows: 2 }}
                    onChange={(e) => {
                        state.suggestion = e.currentTarget.value;
                    }}
                    onBlur={() => {
                        handleOnBlurSuggestion();
                    }}
                />
                <div className="aiFieldFooter">
                    <span className="aiQuestionIcon">
                        <Wand />
                        Copilot suggestion
                    </span>
                    <div className="aiFieldActions">
                        <Button
                            type="primary"
                            danger
                            icon={<CloseOutlined />}
                            onClick={() => handleSuggestion("")}
                        />
                        <Button
                            type="primary"
                            className="checkedIcon"
                            icon={<CheckOutlined />}
                            onClick={() => handleSuggestion(state.suggestion)}
                        />
                    </div>
                </div>
            </div>
        );
    }, [state.suggestion]);

    const SelectField = observer(({ defaultValue }) => {
        return (
            <Select
                defaultValue={defaultValue}
                onChange={setSelectedValue}
                placeholder="Please select"
                disabled={readonly}
            >
                {options.map((option) => (
                    <Select.Option
                        key={option.value}
                        value={option.value}
                        selected={option.value === defaultValue}
                    >
                        {option.value}
                    </Select.Option>
                ))}
            </Select>
        );
    });

    return (
        <div className={classes("fieldContainer", { dangerFieldContainer: state.showAIField })}>
            <div className="fieldLabel">
                <span className="text">{additionalData?.title}</span>
                {additionalData.helpMessage && (
                    <Tooltip title={additionalData.helpMessage} placement="topRight">
                        <QuestionCircleOutlined />
                    </Tooltip>
                )}

                {additionalData.ai?.question && (
                    <Tooltip title={additionalData.ai?.question} placement="topRight">
                        <span className="aiQuestionIcon">
                            <Wand />
                        </span>
                    </Tooltip>
                )}
            </div>

            <div className="fieldContent">
                {type === "text" &&
                    (state.showAIField ? (
                        AITextField
                    ) : (
                        <TextInput
                            value={state.defaultValue}
                            onChange={textChanged}
                            readOnly={readonly}
                            onBlur={(value) => {
                                additionalData.value = value;
                                saveText();
                            }}
                        />
                    ))}
                {type === "select" && <SelectField defaultValue={state.defaultValue} />}
            </div>
        </div>
    );
});

export default AdditionalDataField;
