import React from "react";

export default function () {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4H7.5C6.09375 4 5 5.125 5 6.5V17.5C5 18.9062 6.09375 20 7.5 20H18.5C18.75 20 19 19.7812 19 19.5V19C19 18.7812 18.8125 18.5625 18.5938 18.5312C18.4375 18.125 18.4375 16.9062 18.5938 16.5C18.8125 16.4688 19 16.25 19 16V9H17.5V15H7.5C7.125 15 6.78125 15.0938 6.5 15.2188V6.5C6.5 5.96875 6.9375 5.5 7.5 5.5H12V4ZM17.3125 18.5C17.2188 17.9688 17.2188 17.0625 17.3125 16.5H7.5C6.9375 16.5 6.5 16.9688 6.5 17.5C6.5 18.0625 6.9375 18.5 7.5 18.5H17.3125Z"
                fill="#B03DB0"
            />
            <path
                d="M11.2568 7.33496C11.3122 7.4554 11.3398 7.57585 11.3398 7.69629C11.3398 7.81348 11.3187 7.89486 11.2764 7.94043C11.234 7.98275 11.1657 8.00391 11.0713 8.00391H10.6953C10.679 8.42057 10.6725 9.11882 10.6758 10.0986H13.1025C13.0993 9.11882 13.0879 8.42057 13.0684 8.00391H12.5166C12.4613 7.86068 12.4336 7.72396 12.4336 7.59375C12.4336 7.5026 12.4564 7.4375 12.502 7.39844C12.5508 7.35612 12.6224 7.33496 12.7168 7.33496H15.2412C15.2965 7.4554 15.3242 7.57585 15.3242 7.69629C15.3242 7.81348 15.3031 7.89486 15.2607 7.94043C15.2184 7.98275 15.1517 8.00391 15.0605 8.00391H14.6357C14.6162 8.42057 14.6081 9.10742 14.6113 10.0645C14.6113 11.8255 14.6211 12.9144 14.6406 13.3311H15.2412C15.2965 13.4482 15.3242 13.5687 15.3242 13.6924C15.3242 13.8063 15.3031 13.8861 15.2607 13.9316C15.2184 13.9772 15.1517 14 15.0605 14H12.5166C12.4613 13.8568 12.4336 13.7184 12.4336 13.585C12.4336 13.4971 12.4564 13.4336 12.502 13.3945C12.5508 13.3522 12.6224 13.3311 12.7168 13.3311H13.0732C13.0895 12.986 13.0993 12.1803 13.1025 10.9141H10.6758C10.679 12.1803 10.6872 12.986 10.7002 13.3311H11.2568C11.3122 13.4482 11.3398 13.5687 11.3398 13.6924C11.3398 13.8063 11.3187 13.8861 11.2764 13.9316C11.234 13.9772 11.1657 14 11.0713 14H8.53223C8.47363 13.8568 8.44434 13.7184 8.44434 13.585C8.44434 13.4971 8.46875 13.4336 8.51758 13.3945C8.56641 13.3522 8.63802 13.3311 8.73242 13.3311H9.1377C9.15397 12.9144 9.16211 11.8239 9.16211 10.0596C9.15885 9.10579 9.14909 8.42057 9.13281 8.00391H8.53223C8.47363 7.86068 8.44434 7.72396 8.44434 7.59375C8.44434 7.5026 8.46875 7.4375 8.51758 7.39844C8.56641 7.35612 8.63802 7.33496 8.73242 7.33496H11.2568Z"
                fill="#B03DB0"
            />
            <path
                d="M18.5096 1.83944C18.3374 1.85767 18.0696 1.89412 17.8974 1.93058C17.9739 2.05819 18.0313 2.29517 18.0504 2.42277C18.0504 2.98787 17.553 3.4436 16.9791 3.4436C16.8261 3.4436 16.5774 3.38892 16.4626 3.316C16.4052 3.48006 16.367 3.71704 16.367 3.8811C16.367 5.01131 17.3235 5.92277 18.5096 5.92277C19.6957 5.92277 20.6522 5.01131 20.6522 3.8811C20.6522 2.76912 19.6957 1.83944 18.5096 1.83944ZM23.9426 3.6259C22.9096 1.6936 20.8435 0.381104 18.5096 0.381104C16.1565 0.381104 14.0904 1.6936 13.0574 3.6259C13.0191 3.69881 13 3.80819 13 3.89933C13 3.97225 13.0191 4.08162 13.0574 4.15454C14.0904 6.08683 16.1565 7.3811 18.5096 7.3811C20.8435 7.3811 22.9096 6.08683 23.9426 4.15454C23.9809 4.08162 24 3.97225 24 3.8811C24 3.80819 23.9809 3.69881 23.9426 3.6259ZM18.5096 6.5061C16.6157 6.5061 14.8748 5.5035 13.9565 3.8811C14.8748 2.25871 16.6157 1.2561 18.5096 1.2561C20.3843 1.2561 22.1252 2.25871 23.0435 3.8811C22.1252 5.5035 20.3843 6.5061 18.5096 6.5061Z"
                fill="#B03DB0"
            />
        </svg>
    );
}
