/* eslint-disable react-hooks/rules-of-hooks */
import { createStore, LayerRenderStatus } from "@react-pdf-viewer/core";
import * as React from "react";
import { Trigger } from "@react-pdf-viewer/highlight";

import { HIGHLIGHT_LAYER_ATTR, HIGHLIGHT_PAGE_ATTR } from "./constants";
import { HighlightAreaList } from "./highlight-area-list";
import { NO_SELECTION_STATE, SelectedState, SELECTING_STATE } from "./highlight-state";
import { Tracker } from "./tracker";

const TEXT_LAYER_END_SELECTOR = "rpv-highlight__selected-end";

export const highlightPlugin = (props) => {
    const highlightPluginProps = Object.assign({}, { trigger: Trigger.TextSelection }, props);

    const store = React.useMemo(
        () =>
            createStore({
                highlightState: NO_SELECTION_STATE,
            }),
        [],
    );

    const renderViewer = (props) => {
        const currentSlot = props.slot;
        if (highlightPluginProps.trigger !== Trigger.TextSelection) {
            return currentSlot;
        }

        if (currentSlot.subSlot && currentSlot.subSlot.children) {
            currentSlot.subSlot.children = (
                <>
                    <Tracker store={store} />
                    {currentSlot.subSlot.children}
                </>
            );
        }

        return currentSlot;
    };

    const handleMouseDown = (textLayerRender) => (e) => {
        const textLayer = textLayerRender.ele;
        const pageRect = textLayer.getBoundingClientRect();
        const highlightState = store.get("highlightState");
        if (highlightState instanceof SelectedState) {
            const mouseTop = e.clientY - pageRect.top;
            const mouseLeft = e.clientX - pageRect.left;

            // Check if the user clicks inside a highlighting area
            const userClickedInsideArea = highlightState.highlightAreas
                .filter((area) => area.pageIndex === textLayerRender.pageIndex)
                .find((area) => {
                    const t = (area.top * pageRect.height) / 100;
                    const l = (area.left * pageRect.width) / 100;
                    const h = (area.height * pageRect.height) / 100;
                    const w = (area.width * pageRect.width) / 100;
                    return (
                        t <= mouseTop && mouseTop <= t + h && l <= mouseLeft && mouseLeft <= l + w
                    );
                });
            if (userClickedInsideArea) {
                // Cancel the selection
                window.getSelection().removeAllRanges();
                store.update("highlightState", NO_SELECTION_STATE);
            } else {
                store.update("highlightState", SELECTING_STATE);
            }
        } else {
            store.update("highlightState", NO_SELECTION_STATE);
        }

        // Create an invisible element from the current position to the end of page
        // It prevents users from selecting the forward text
        const selectionTop = ((e.clientY - pageRect.top) * 100) / pageRect.height;
        const selectEnd = textLayer.querySelector(`.${TEXT_LAYER_END_SELECTOR}`);
        if (selectEnd && e.target !== textLayer) {
            selectEnd.style.top = `${Math.max(0, selectionTop)}%`;
        }
    };

    const handleMouseUp = (textLayerRender) => (e) => {
        const selectEnd = textLayerRender.ele.querySelector(`.${TEXT_LAYER_END_SELECTOR}`);
        if (selectEnd) {
            selectEnd.style.removeProperty("top");
        }
    };

    const handleContextMenu = () => (e) => {
        e.preventDefault();
    };

    const onTextLayerRender = (e) => {
        if (highlightPluginProps.trigger !== Trigger.TextSelection) {
            return;
        }

        const mouseDownHandler = handleMouseDown(e);
        const mouseUpHandler = handleMouseUp(e);
        const contextMenuHandler = handleContextMenu(e);
        const textEle = e.ele;

        if (e.status === LayerRenderStatus.PreRender) {
            textEle.removeEventListener("mousedown", mouseDownHandler);
            textEle.removeEventListener("mouseup", mouseUpHandler);
            textEle.removeEventListener("contextmenu", contextMenuHandler);

            const selectEndEle = textEle.querySelector(`.${TEXT_LAYER_END_SELECTOR}`);
            if (selectEndEle) {
                textEle.removeChild(selectEndEle);
            }
        } else if (e.status === LayerRenderStatus.DidRender) {
            textEle.addEventListener("mousedown", mouseDownHandler);
            textEle.addEventListener("mouseup", mouseUpHandler);
            textEle.addEventListener("contextmenu", contextMenuHandler);

            // Set some special attributes so we can query the text later
            textEle.setAttribute(HIGHLIGHT_LAYER_ATTR, "true");
            textEle
                .querySelectorAll(".rpv-core__text-layer-text")
                .forEach((span) => span.setAttribute(HIGHLIGHT_PAGE_ATTR, `${e.pageIndex}`));

            // Create an element that improves the text selection
            if (!textEle.querySelector(`.${TEXT_LAYER_END_SELECTOR}`)) {
                const selectEnd = document.createElement("div");
                selectEnd.classList.add(TEXT_LAYER_END_SELECTOR);
                selectEnd.classList.add("rpv-core__text-layer-text--not");
                textEle.appendChild(selectEnd);
            }
        }
    };

    const renderPageLayer = (renderPageProps) => (
        <HighlightAreaList
            pageIndex={renderPageProps.pageIndex}
            renderHighlightContent={highlightPluginProps.renderHighlightContent}
            renderHighlightTarget={highlightPluginProps.renderHighlightTarget}
            renderHighlights={highlightPluginProps.renderHighlights}
            store={store}
        />
    );

    const jumpToHighlightArea = (area) => {
        const jumpToDestination = store.get("jumpToDestination");
        if (jumpToDestination) {
            const bottomOffset = (_, viewportHeight) => ((100 - area.top) * viewportHeight) / 100;
            const leftOffset = (viewportWidth, _) => ((100 - area.left) * viewportWidth) / 100;
            jumpToDestination(area.pageIndex, bottomOffset, leftOffset);
        }
    };

    return {
        install: (pluginFunctions) => {
            store.update("jumpToDestination", pluginFunctions.jumpToDestination);
            store.update("getPagesContainer", pluginFunctions.getPagesContainer);
        },
        onViewerStateChange: (viewerState) => {
            store.update("rotation", viewerState.rotation);
            return viewerState;
        },
        onTextLayerRender,
        renderPageLayer,
        renderViewer,
        jumpToHighlightArea,
    };
};
