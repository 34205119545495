import { Extension, isTextSelection } from "@tiptap/core";
import { Slice } from "prosemirror-model";
import { NodeSelection } from "prosemirror-state";

export default Extension.create({
    name: "utils",

    addCommands() {
        return {
            /**
             * Find the closest parent node based on the current selection
             */
            parentNode: (type) => ({ editor }) => {
                const { selection } = editor.state;
                const node = selection.$anchor.path
                    .slice()
                    .reverse()
                    .find((item) => item.type?.name === type);

                return node;
            },

            /**
             * Find the node depth based on the current selection
             */
            nodeDepth: (node) => ({ editor }) => {
                const { selection } = editor.state;
                const index = selection.$anchor.path.indexOf(node);
                console.log(index);

                return index;
            },

            /**
             * Unwrap a node and place its content on its place and remove the node.
             */
            unwrap: (type) => ({ editor, commands, tr }) => {
                const { selection } = editor.state;
                const { $cursor, $head } = selection;

                // find the node and it's depth so we can create a node selection
                let node, depth;
                for (let i = $head.depth; i > 0; i--) {
                    depth = i;
                    node = $head.node(i);

                    if (!node) {
                        return false;
                    }

                    if (node.type.name === type) {
                        break;
                    }
                }

                const pos = $head.before(depth);
                const cursor = ($cursor ?? $head).pos - 1;
                const nodeSel = NodeSelection.create(editor.state.doc, pos);

                tr.setSelection(nodeSel);

                const slice = new Slice(node.content, depth - 1, depth - 1);
                tr.replaceSelection(slice);
                commands.setTextSelection(cursor);

                return true;
            },

            /**
             * Check if the selection spans accross multiple block elements
             */
            isBlockSelection: () => ({ editor }) => {
                const { selection, doc } = editor.state;
                const slice = doc.slice(selection.from, selection.to);
                return !!slice.content.content.find((node) => node.isBlock);
            },

            // Check if text selection contains text
            hasTextSelection: () => ({ editor }) => {
                const { doc, selection } = editor.state;

                const hasText =
                    isTextSelection(selection) &&
                    doc.textBetween(selection.from, selection.to).length > 0;
                if (!selection.empty && hasText) {
                    return true;
                }

                return false;
            },
            getNodePos: (node) => ({ editor }) => {
                const { doc } = editor.state;
                if (node) {
                    let found;
                    doc.descendants((des, pos) => {
                        if (des.eq(node)) {
                            found = { from: pos, to: pos + des.nodeSize };
                            return false;
                        }
                    });

                    return found;
                }

                return;
            },
            editCaption: (edit = true) => ({ editor }) => {
                const selection = editor?.state?.selection;

                if (editor.can().editReportCaption()) {
                    if (edit) {
                        if (selection?.node) {
                            editor.emit("editReportCaption", { node: selection.node });
                        }
                    } else {
                        return true;
                    }
                } else if (editor.can().editImageCaption()) {
                    if (edit) {
                        if (selection?.node) {
                            editor.emit("editImageCaption", { node: selection.node });
                        }
                    } else {
                        return true;
                    }
                } else if (editor.can().editTableCaption()) {
                    if (edit) {
                        const table = selection?.$head?.node(1);
                        if (table) {
                            editor.emit("editTableCaption", { node: table });
                        }
                    } else {
                        return true;
                    }
                }

                return false;
            },
        };
    },
});
