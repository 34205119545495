import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, Button, Input, Select } from "antd";
import UploadButton from "@app/components/form/upload/button";

import Group from "../../group";
import format from "@app/lib/format";

import "./style.scoped.scss";

const defaultStyleValue = "defaultStyleTemplate";

const DocumentStyles = observer(({ form, config, onSave }) => {
    const [style, setStyle] = useState({});

    const captionModes = [
        { label: "Section Numbering", value: "section" },
        { label: "Global Numbering", value: "global" },
    ];

    const headingModes = [
        { label: "Numbers followed by a dot", value: "withDot" },
        { label: "No dot after the numbers", value: "noDot" },
    ];

    const captionCrossRefModes = [
        { label: "Caption Index", value: "index" },
        { label: "Caption Index + Title", value: "full" },
        { label: "Caption Title", value: "title" },
    ];

    const sectionCrossRefModes = [
        { label: "Section Index", value: "index" },
        { label: "Section Index + Title", value: "full" },
        { label: "Section Title", value: "title" },
    ];

    const tocLevelModes = [
        { label: "Heading 1", value: 1 },
        { label: "Heading 1 - 2", value: 2 },
        { label: "Heading 1 - 3", value: 3 },
        { label: "Heading 1 - 4", value: 4 },
        { label: "Heading 1 - 5", value: 5 },
    ];

    useEffect(() => {
        const style = config.reportDocuments?.style ?? {};
        style.file = style.file || defaultStyleValue;

        setStyle(style);
        form.setFieldsValue(style);
    }, [config]);

    /**
     * Handle the new file upload event
     */
    const onUpload = (data) => {
        setStyle({
            ...style,
            file: data._id,
        });
    };

    function onSuccess(data) {
        onSave({
            ...style,
            file: style.file !== defaultStyleValue ? style.file : "",
        });
    }

    function onChange(data) {
        if (data.file) {
            data.file = data.file._id;
        }

        setStyle({
            ...style,
            ...data,
        });
    }

    return (
        <Group title="Document Style" className="style-form">
            <Form layout="vertical" form={form} onFinish={onSuccess} onValuesChange={onChange}>
                <div className="config">
                    <div className="entry template">
                        <em>Document Template</em>
                        <div className={`value ${!style.file ? "error" : ""}`}>
                            <Input
                                value={
                                    style.file
                                        ? "Docx Style Template"
                                        : "Missing template file. Please upload a template"
                                }
                                readOnly
                            />
                            {style.file && (
                                <a href={format.file(style.file)} target="_blank" rel="noreferrer">
                                    <Button style={{ marginRight: `15px` }}>Download</Button>
                                </a>
                            )}
                            <Form.Item name="file" className="upload-button">
                                <UploadButton
                                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    onChange={onUpload}
                                    label="Upload"
                                ></UploadButton>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="entry">
                        <div className="left">
                            <em>Section Numbering</em>

                            <Form.Item name="sectionNumbering">
                                <Select
                                    optionFilterProp="label"
                                    placeholder="Please select numbering mode"
                                >
                                    {headingModes.map((mode) => {
                                        return (
                                            <Select.Option
                                                key={mode.value}
                                                value={mode.value}
                                                label={mode.label}
                                            >
                                                {mode.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="right">
                            <em>Preview</em>
                            <p className="mt-5 section-title">
                                {style.sectionNumbering === "withDot"
                                    ? "1. Example section title"
                                    : "1 Example section title"}
                            </p>
                        </div>
                    </div>
                    <div className="entry">
                        <div className="left">
                            <em>Caption Numbering</em>

                            <Form.Item name="captionIndex">
                                <Select
                                    optionFilterProp="label"
                                    placeholder="Please select numbering mode"
                                >
                                    {captionModes.map((mode) => {
                                        return (
                                            <Select.Option
                                                key={mode.value}
                                                value={mode.value}
                                                label={mode.label}
                                            >
                                                {mode.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="right">
                            <em>Preview</em>
                            <p className="mt-10 caption">
                                {style.captionIndex === "global"
                                    ? "Table 1: Example of a global indexing"
                                    : "Table 1-3: Example of a section based indexing"}
                            </p>
                        </div>
                    </div>
                    <div className="entry">
                        <div className="left">
                            <em>Table/Figure Cross-Reference</em>

                            <Form.Item name="captionRef">
                                <Select
                                    optionFilterProp="label"
                                    placeholder="Please select render mode"
                                >
                                    {captionCrossRefModes.map((mode) => {
                                        return (
                                            <Select.Option
                                                key={mode.value}
                                                value={mode.value}
                                                label={mode.label}
                                            >
                                                {mode.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="right">
                            <em>Preview</em>
                            <p className="mt-5">
                                ... An example text to demonstrate how the cross-reference will be
                                rendered in the document. As you can see in
                                <b>
                                    {" "}
                                    {style.captionRef === "index" && "Table 1"}
                                    {style.captionRef === "title" && "The title of the table"}
                                    {style.captionRef === "full" &&
                                        "Table 1: The title of the table"}
                                </b>{" "}
                                the values are...
                            </p>
                        </div>
                    </div>
                    <div className="entry">
                        <div className="left">
                            <em>Section Cross-Reference</em>

                            <Form.Item name="sectionRef">
                                <Select
                                    optionFilterProp="label"
                                    placeholder="Please select render mode"
                                >
                                    {sectionCrossRefModes.map((mode) => {
                                        return (
                                            <Select.Option
                                                key={mode.value}
                                                value={mode.value}
                                                label={mode.label}
                                            >
                                                {mode.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="right">
                            <em>Preview</em>

                            <p style={{ marginTop: 5 }}>
                                ... An example text to demonstrate how the cross-reference will be
                                rendered in the document. As you can see in
                                <b>
                                    &nbsp;
                                    {style.sectionRef === "index" && "Section 1"}
                                    {style.sectionRef === "title" && "The title of the section"}
                                    {style.sectionRef === "full" &&
                                        "Section 1: The title of the section"}
                                </b>
                                &nbsp; the values are...
                            </p>
                        </div>
                    </div>
                    <div className="entry">
                        <div className="left">
                            <em>TOC Levels</em>

                            <Form.Item name="tocLevel">
                                <Select
                                    optionFilterProp="label"
                                    placeholder="Please select TOC level"
                                >
                                    {tocLevelModes.map((mode) => {
                                        return (
                                            <Select.Option
                                                key={mode.value}
                                                value={mode.value}
                                                label={mode.label}
                                            >
                                                {mode.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="right">
                            <em>Preview</em>

                            <p style={{ marginTop: 5 }}>
                                {[...Array(style.tocLevel ?? 0)].map((_, i) => {
                                    const level = i + 1;
                                    const number = [...Array(level)].map(() => 1).join(".");
                                    const padding = (level - 1) * 15;

                                    return (
                                        <>
                                            <b style={{ paddingLeft: padding }}>
                                                {number}. Heading Level {level}
                                            </b>
                                            <br />
                                        </>
                                    );
                                })}
                            </p>
                        </div>
                    </div>
                </div>
            </Form>
        </Group>
    );
});

export default DocumentStyles;
