import { mergeAttributes } from "@tiptap/core";
import OrderedList from "@tiptap/extension-ordered-list";

export default OrderedList.extend({
    renderHTML({ HTMLAttributes }) {
        delete HTMLAttributes.start;

        return ["ol", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
});
