import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Tooltip } from "antd";

import report from "@app/state/store/report";
import "../style/tags.scoped.scss";

export const Tags = observer(({ value, ...rest }) => {
    const list = report.config?.contentTags || [];
    const tags = list.filter((entry) => {
        return value.indexOf(entry.label) >= 0;
    });
    const MAX_LENGTH = 15;

    return (
        <div className={classNames("tags")} {...rest}>
            {tags.map((tag) => {
                const { color, label = "" } = tag;
                const lengthyTag = label.length > MAX_LENGTH;

                const tagElement = (
                    <div className={`tag ${color}`} key={label}>
                        <label>{lengthyTag ? `${label.slice(0, MAX_LENGTH)}...` : label}</label>
                    </div>
                );

                return lengthyTag ? (
                    <Tooltip placement="left" title={label} key={label}>
                        {tagElement}
                    </Tooltip>
                ) : (
                    tagElement
                );
            })}
        </div>
    );
});

export default Tags;
