import React from "react";

import "./pulse-circle.scoped.scss";

export const PulseCircle = ({ style }) => {
    const { top, left, bottom, right } = style;

    return <div className="pulse-circle" style={{ top, left, bottom, right }}></div>;
};

export default PulseCircle;
