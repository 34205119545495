import { Mark } from "@tiptap/core";
import cn from "classnames";

import { ReportDocumentSection } from "@app/constants";

import "./style.scss";
import preventEditingIfNotInTemplateMode from "./helpers/preventEditingIfNotInTemplateMode";

const generateClassAttributes = ({ isHidden }) => ({
    class: cn({
        "help-text": true,
        hidden: isHidden,
    }),
});

const parseHTMLAttributes = (node) => ({
    isHidden: node.getAttribute("isHidden") === "true",
});

let isHiddenLocal = false;

export const HelpTextMark = Mark.create({
    name: ReportDocumentSection.HELP_TEXT.MARK_NAME,
    inline: true,
    inclusive: false,
    inclusiveRight: false,

    addAttributes() {
        return {
            isHidden: {
                default: false,
            },
            class: {
                default: undefined,
                renderHTML: generateClassAttributes,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `span.${ReportDocumentSection.HELP_TEXT.MARK_NAME}`,
                getAttrs: parseHTMLAttributes,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["span", HTMLAttributes, 0];
    },

    addCommands() {
        return {
            toggleHelpTextVisibility: () => ({ editor, tr }) => {
                isHiddenLocal = !isHiddenLocal;

                editor.state.doc.descendants((node, pos) => {
                    if (!node) {
                        console.log("Node is undefined!", pos);
                        return;
                    }

                    if (!node.isInline) {
                        return;
                    }

                    node.marks.forEach((mark) => {
                        const { type, attrs } = mark;
                        if (type.name === ReportDocumentSection.HELP_TEXT.MARK_NAME) {
                            const newAttrs = {
                                ...attrs,
                                isHidden: isHiddenLocal,
                            };
                            const to = pos + (node.nodeSize || 0);
                            tr = tr
                                .removeMark(pos, to, type)
                                .addMark(pos, to, type.create(newAttrs));
                        }
                    });

                    setTimeout(() => {
                        try {
                            editor.view.dispatch(tr);
                        } catch (_e) {
                            // Intentionally ignoring the error as it's non-critical
                        }
                    }, 200);
                });
            },
        };
    },
    addProseMirrorPlugins() {
        const plugins = [];
        plugins.push(preventEditingIfNotInTemplateMode({ editor: this.editor }));

        return plugins;
    },
});

export default HelpTextMark;
