import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";

function handlePasteText(value) {
    value = sanitize(value);
    return value;
}

function handlePasteHtml(value, editor) {
    value = sanitize(value);
    value = sanitizeTable(value, editor);

    return value;
}

function sanitize(value) {
    /* eslint-disable no-control-regex */
    value = value.replace(/\u0002/gi, "-");
    value = value.replace(/[\u0001-\u001f]/gi, " ");
    value = value.replace(/[\u0080-\u009F]/gi, " ");

    return value;
}

function sanitizeTable(html, editor) {
    if (html.includes("</tr>")) {
        const cursor = editor.state.tr.selection.$cursor;
        const isLandscape = editor.commands.parentNode("landscape");
        const parentDepth = isLandscape ? 2 : 1;
        const notRootLevel = cursor?.depth > parentDepth;

        if (notRootLevel) {
            // retrieve the parent node pos and create a paragraph after it
            const parentNode = cursor.node(parentDepth);
            const nodePos = editor.commands.getNodePos(parentNode);
            if (nodePos) {
                editor
                    .chain()
                    .focus()
                    .insertContentAt(nodePos.to, "</p>")
                    .setTextSelection(nodePos.to)
                    .run();
            }
        }

        const wrapper = document.createElement("div");
        wrapper.innerHTML = html;

        const tables = wrapper.querySelectorAll("table");
        for (const table of tables) {
            const tbody = table.querySelector("tbody");
            const rows = Array.from(tbody.childNodes).filter((node) => node.nodeType === 1); // Leave only element nodes

            // remove empty rows
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                const next = rows[i + 1];

                // reset the rowspan if the next row is empty
                if (!next || !next.childNodes.length) {
                    const cells = row.querySelectorAll("td");
                    for (const cell of cells) {
                        cell.removeAttribute("rowspan");
                    }
                }

                if (!row.childNodes.length) {
                    tbody.removeChild(row);
                }
            }
        }

        html = wrapper.innerHTML;
    }

    return html;
}

export default Extension.create({
    name: "pasteExtension",

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey("transformPasted"),
                props: {
                    transformPastedText: handlePasteText,
                    transformPastedHTML: (value) => handlePasteHtml(value, this.editor),
                },
            }),
        ];
    },
});
