import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Drawer } from "@app/components/drawer";
import { Input, Button } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import UserChip from "./chip";

import "./style/picker.scoped.scss";
import users from "@app/state/store/users/picker";

const UserPicker = observer(
    ({
        role,
        project,
        client,
        visible,
        title = "Add Users",
        addButton = "Add",
        exclude = [],
        onClose,
        onPick,
    }) => {
        const excludeMap = {};
        exclude.map((user) => {
            excludeMap[user._id] = true;
        });

        // load the list when the component mounts
        useEffect(() => {
            if (visible) {
                users.load(role, { client, project });
            }
        }, [role, visible, client, project]);

        /**
         * Search in the list
         */
        const search = (query) => {
            users.search = query;
        };

        /**
         * Filter the selected users and emit an add event
         */
        const handleAdd = () => {
            let list = users.list.filter((user) => user.$selected);
            onPick(list);

            // clear the search string
            users.search = null;
        };

        /**
         * Clear the search after close
         */
        const handleClose = () => {
            onClose();
            users.search = null;
        };

        /**
         * Render the drawer footer
         */
        const Footer = observer(() => {
            const list = users.list.filter((user) => user.$selected);
            const disabled = !list.length;

            return (
                <div className="button-group">
                    <Button type="primary" onClick={handleAdd} disabled={disabled}>
                        {addButton}
                    </Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </div>
            );
        });

        /**
         * Render the search line
         */
        const Search = observer(() => {
            const searchRef = useRef(null);

            // focus the search field on render
            useEffect(() => {
                if (searchRef.current) {
                    searchRef.current.focus();
                }
            }, []);

            return (
                <div className="search">
                    <Input.Search
                        placeholder="Search"
                        size="large"
                        value={users.search}
                        prefix={<UserAddOutlined />}
                        bordered={false}
                        onChange={(event) => {
                            search(event.target.value);
                        }}
                        onSearch={search}
                        ref={searchRef}
                        allowClear
                    />
                </div>
            );
        });

        /**
         * Render the list of users
         */
        const Users = observer(() => {
            return (
                <>
                    {users.filtered.map((user) => {
                        if (excludeMap[user._id]) {
                            return;
                        }

                        return <UserChip user={user} className="user" key={user._id} checkbox />;
                    })}
                </>
            );
        });

        return (
            <Drawer
                title={title}
                inline={false}
                padding={false}
                width={400}
                visible={visible}
                onClose={handleClose}
                footer={<Footer />}
                closable
            >
                <div className="picker">
                    <div className="search">
                        <Search />
                    </div>
                    <div className="list">
                        <Users />
                    </div>
                </div>
            </Drawer>
        );
    },
);

export default UserPicker;
