import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Menu, Dropdown, Badge, Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { NotificationOutlined } from "@ant-design/icons";
import format from "@app/lib/format";
import Avatar from "@app/components/user/avatar";
import notification from "@app/state/store/notification";
import { NotificationType, CAPTIS_USER } from "@app/constants";
import captisLogo from "@app/assets/logo-icon.svg";
import http from "@app/lib/http";

import "./style/notification.scoped.scss";

var state = observable({
    loading: true,
});

// attach to the http events
http.on("loading", (loading) => {
    state.loading = loading;
});

const Message = observer(({ message }) => {
    if (!message) {
        return;
    }

    const styleName = message.read === true ? "actor-seen" : "actor";
    let messageBody;
    if (
        [
            NotificationType.ArticleComment,
            NotificationType.SearchComment,
            NotificationType.DocumentComment,
            NotificationType.ProjectComment,
            NotificationType.ReportDocumentComment,
        ].includes(message.type)
    ) {
        messageBody = `${message.from?.fullName} mentioned you in a comment.`;
    } else if (NotificationType.ReviewStage === message.type) {
        messageBody = "A review stage have started.";
    } else if (NotificationType.ReviewComplete === message.type) {
        messageBody = "The review has completed.";
    } else if (NotificationType.SearchAutoUpdate === message.type) {
        messageBody = `${message.context?.newResults} new results found for literature searches run in the ${message.context?.projectName} project.`;
    }

    if (CAPTIS_USER.fullName === message.from?.fullName) {
        message.from.avatar = captisLogo;
    }

    return (
        <div className="notification">
            <Tooltip title={message.from.fullName}>
                <Avatar className="notificationAvatar" user={message.from} size={40} />
            </Tooltip>
            <div className="details">
                <div className="text">
                    <em className={styleName}>{messageBody}</em>
                </div>
                <div className="time"> {format.datetime(message.created)} </div>
            </div>
        </div>
    );
});

const Notification = observer(() => {
    const navigateTo = async (e, message) => {
        if (state.loading) {
            e.preventDefault();
            e.stopPropagation();

            return;
        }

        if (message.read === false) {
            await notification.seen(message);
        }
    };

    const markAllAsRead = async () => {
        await notification.markAllAsRead();
    };

    const Render = observer(({ message }) => {
        if (Object.values(NotificationType).includes(message.type)) {
            return <Message message={message} />;
        }
    });

    const notifications = (
        <Menu className="notifications-list">
            <div className="title">
                Notifications
                {notification.unread > 0 && (
                    <Button type="link" onClick={markAllAsRead}>
                        Mark all as read
                    </Button>
                )}
            </div>
            {notification.list?.map((message) => {
                return (
                    <Menu.Item key={message._id}>
                        <Link
                            to={message.link}
                            onClick={(e) => navigateTo(e, message)}
                            className={`${state.loading ? "loading" : ""}`}
                        >
                            {<Render message={message} />}
                        </Link>
                    </Menu.Item>
                );
            })}
            <>
                {notification.filter?.hasNextPage && (
                    <Button
                        type="link"
                        onClick={() => {
                            notification.filter.next();
                        }}
                    >
                        Load More
                    </Button>
                )}
            </>
        </Menu>
    );

    return (
        <Dropdown overlay={notifications} placement="bottomLeft" trigger="click">
            <div className="notifications">
                <Badge
                    offset={[4, -2]}
                    size="small"
                    count={notification.unread}
                    overflowCount={100}
                >
                    <NotificationOutlined className="icon" />
                </Badge>
            </div>
        </Dropdown>
    );
});

export default Notification;
