import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { Page } from "@app/components/page";
import { Viewer } from "@app/components/report-document-history";
import { events } from "@app/lib/store";

import reportDocumentsStore from "@app/state/store/report-document/report-documents";
import sectionsStore from "@app/state/store/report-document/report-document-sections";
import dictionaryStore from "@app/state/store/report/dictionary/pick";
import abbreviationStore from "@app/state/store/report/abbreviation/pick";
import citationsStore from "@app/state/store/report/citation/pick";
import commentStore from "@app/state/store/report-document/comment";
import versionStore from "@app/state/store/report-document/version";
import documentStore from "@app/state/store/report-document/document";
import versionAliasStore from "@app/state/store/report-document/version-alias";

import ReportDocumentHistorySidebar from "@app/components/report-document-history/sidebar";
import setup from "@app/state/store/projects/setup";

import "./report-document-history.scoped.scss";
import Loader from "@app/components/loader/content";
import VersionNavigator from "./version-navigator";

const ReportDocumentHistory = observer(() => {
    const { project, reportDocumentId } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const reportDocument = reportDocumentsStore.reportDocument;

    useEffect(() => {
        if (reportDocumentId && project) {
            load();

            events.on("reportDocument.viewer.reload", () => {
                load();
            });

            events.on("reportDocument.toc.reload", async () => {
                await reportDocumentsStore.getReportDocumentSections(reportDocumentId);
                sectionsStore.sections = reportDocumentsStore.reportDocumentSections;
            });

            versionAliasStore.reportDocumentId = reportDocumentId;
            versionStore.reportDocumentId = reportDocumentId;
            versionAliasStore.projectId = project;
            versionStore.projectId = project;
        }

        return () => {
            commentStore.reset();
            abbreviationStore.resetUsage();
            versionAliasStore.reset();
            versionStore.reset();

            events.removeAllListeners(["reportDocument.viewer.reload"]);
            events.removeAllListeners(["reportDocument.toc.reload"]);
        };
    }, [project, reportDocumentId]);

    if (!reportDocument) {
        return null;
    }

    async function load() {
        setLoading(true);

        if (project) {
            reportDocumentsStore.setProjectId(project);
            await setup.load(project);
        }

        if (reportDocumentId) {
            sectionsStore.section = null;
            sectionsStore.sections = [];

            await reportDocumentsStore.load(reportDocumentId);
            sectionsStore.sections = reportDocumentsStore.reportDocumentSections;

            documentStore.init(reportDocumentId, sectionsStore.sections);

            await Promise.all([
                dictionaryStore.load(),
                abbreviationStore.load(),
                citationsStore.load(),
                versionAliasStore.load(),
                versionStore.load(),
            ]);
        }

        setLoading(false);
    }

    const close = () => {
        history.push(`../${reportDocumentId}`);
    };

    const onCompareVersions = () => {
        versionAliasStore.onShowCompareVersionsModal();
    };

    return (
        <Page className={"report-document"}>
            <Page.Header closable={true} onClose={close} className={"report-document-header"}>
                <Page.Header.Left shrink>
                    <Page.Title>
                        <div>{reportDocument?.name}</div>
                    </Page.Title>
                </Page.Header.Left>
                <Page.Header.Center style={{ margin: "0 auto" }}>
                    <VersionNavigator />
                </Page.Header.Center>
                <Page.Header.Right shrink>
                    <Tooltip
                        title={
                            versionAliasStore.aliases.length
                                ? "Compare previously created versions"
                                : "No versions created yet, please create a version first"
                        }
                        placement="bottomLeft"
                    >
                        <Button
                            type="primary"
                            style={{ border: "none" }}
                            onClick={onCompareVersions}
                            disabled={!versionAliasStore.aliases.length}
                        >
                            Compare versions
                        </Button>
                    </Tooltip>
                </Page.Header.Right>
            </Page.Header>
            <Page.Layout>
                <Page.Body padding={false}>
                    {loading && (
                        <div className="loader">
                            <Loader loading={true} />
                        </div>
                    )}
                    {!loading && (
                        <Viewer
                            sections={sectionsStore.sections}
                            dictionary={dictionaryStore}
                            citations={citationsStore}
                            abbreviations={abbreviationStore}
                            editingState={[null, () => {}]}
                            onReload={load}
                            documentId={reportDocument._id}
                        />
                    )}
                </Page.Body>
                <ReportDocumentHistorySidebar width={350} />
            </Page.Layout>
        </Page>
    );
});

ReportDocumentHistory.displayName = "ReportDocumentHistory";

export default ReportDocumentHistory;
