export const getTextFromOffsets = (
    textLayerDiv,
    startDivIdx,
    startOffset,
    endDivIdx,
    endOffset,
) => {
    const nodes = [].slice.call(textLayerDiv.children);

    if (startDivIdx < endDivIdx) {
        const startDivText = nodes
            .slice(startDivIdx, startDivIdx + 1)
            .map((node) => node.textContent.substring(startOffset).trim())
            .join(" ");

        const middleDivText = nodes
            .slice(startDivIdx + 1, endDivIdx)
            .map((node) => node.textContent.trim())
            .join(" ");

        const endDivText = nodes
            .slice(endDivIdx, endDivIdx + 1)
            .map((endDiv) =>
                endDiv.textContent.substring(0, endOffset || endDiv.textContent.length),
            )
            .join(" ");
        return `${startDivText} ${middleDivText} ${endDivText}`;
    } else {
        const div = nodes[startDivIdx];
        return div.textContent.substring(startOffset, endOffset || div.textContent.length).trim();
    }
};
