import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { default as qs } from "qs";

import { publish } from "../../../util/events";

export const EventPublisher = () => {
    const location = useLocation();

    useEffect(() => {
        const { node } = qs.parse(location.search, { ignoreQueryPrefix: true });

        if (node) {
            publish("scrollToNode", node);
        }
    }, [location]);

    return null;
};

export default EventPublisher;
