import { computed, action } from "mobx";
import { v4 as uuid } from "uuid";
import BaseStore from "../base";
import { events } from "@app/lib/store";
import http from "@app/lib/http";
import Client from "@app/state/model/client";
import notify from "@app/components/notify";
import Config from "@app/state/model/project/config";
import User from "@app/state/model/user";

const STAGGER_ID = uuid();

/**
 * State management controlling the client details in
 * the client management sections
 */
export class ClientDetails extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            loading: false,
            loaded: false,
            saving: false,
            data: null,
            config: null,
            configuration: null,
            copy: {},
            users: null,
        };
    }

    @computed get busy() {
        return this.loading || this.saving;
    }

    constructor() {
        super();

        events.on("report-document.delete", (id) => {
            if (this.copy.reportDocumentId === id) {
                this.resetCopy();
            }
        });
    }

    /**
     * Load the project configuration
     */
    @action
    async loadConfig(id, configuration) {
        try {
            if (!this.id) {
                await this.load(id);
            }

            // the configuration is loaded
            if (this.configuration === configuration) {
                return true;
            }

            this.loading = true;
            let { data } = await http.get(
                `/client/${this.data._id}/configuration/${configuration}`,
            );

            if (data) {
                this.config = new Config(data);
                this.loading = false;
                this.loaded = true;
                this.configuration = configuration;
            }
            return true;
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.loading = false;
        }
    }

    /**
     * Load client details
     */
    @action
    async load(id) {
        // do not load the client data twice
        if (this.data?._id === id || this.loading) {
            return;
        }

        this.loading = true;
        this.data = null;

        let { data } = await http.get(`/client/${id}`).stagger(STAGGER_ID);

        if (data._id) {
            this.data = new Client(data);
        }

        this.users = data.owners.map((entry) => {
            return new User(entry);
        });

        this.loading = false;
    }

    /**
     * Update client Configuration
     */
    @action
    async updateConfiguration(params) {
        if (!this.data || this.saving) {
            return;
        }

        this.saving = true;

        try {
            const result = await http.post(`/client/${this.data._id}/configuration/`, params);
            this.data = new Client(result.data);

            // emit the update event
            events.emit("client.update", this.data);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "An error occurred while trying to update");
            return false;
        } finally {
            this.saving = false;
        }
    }

    /**
     * Remove client Configuration
     */
    @action
    async deleteConfiguration(configuration) {
        if (!this.data || this.saving) {
            return;
        }

        this.saving = true;

        try {
            let result;
            if (this.data._id && configuration) {
                result = await http.delete(
                    `/client/${this.data._id}/configuration/${configuration}`,
                );
            }

            this.data = new Client(result.data);

            // emit the update event
            events.emit("client.update", this.data);
        } catch (ex) {
            notify.error(ex.response?.data?.error || "An error occurred while trying to update");
            return false;
        } finally {
            this.saving = false;
        }
    }

    /**
     * Update client details
     */
    @action
    async update(params) {
        if (!this.data || this.saving) {
            return;
        }

        this.saving = true;

        try {
            let result;
            if (this.data._id) {
                result = await http.post(`/client/${this.data._id}`, params);
            } else {
                result = await http.put(`/client`, params);
            }

            this.data = new Client(result.data);

            // emit the update event
            events.emit("client.update", this.data);
            return true;
        } catch (ex) {
            notify.error(ex.response?.data?.error);
            return false;
        } finally {
            this.saving = false;
        }
    }

    /**
     * Remove the client
     */
    @action
    async remove() {
        if (!this.data || this.saving) {
            return;
        }

        this.saving = true;
        await http.delete(`/client/${this.data._id}`);

        // emit the delete event
        events.emit("client.delete", this.data);

        this.data = null;
        this.saving = false;
    }

    /**
     * Create a new client
     */
    @action
    create() {
        if (!this.data || this.data._id !== undefined) {
            this.data = new Client();
        }
    }

    @action
    async unload() {
        this.data = null;
        this.config = null;
        this.id = null;
        this.configuration = null;
        this.users = null;
    }

    /**
     * Load the project configuration
     */
    @action
    async updateConfig(params) {
        this.saving = true;

        try {
            if (!this.configuration) {
                await this.loadConfig(params._id, params.configuration);
            }

            let { data } = await http.post(
                `/client/${this.data._id}/configuration/${this.configuration}`,
                params,
            );
            if (data?.configurations) {
                const found = data.configurations.find((item) => item._id === this.configuration);
                this.config.set(found);
            }
        } catch (ex) {
            notify.error(
                ex.response?.data?.error || "An error occurred while updating the details",
            );
        } finally {
            this.saving = false;
        }
    }

    @action resetCopy() {
        this.copy = {};
    }

    @computed
    get isConfigCopied() {
        return this.copy.configId && this.copy.clientId;
    }

    @action
    copyConfig(configId) {
        this.resetCopy();
        this.copy.configId = configId;
        this.copy.clientId = this.data._id;
    }

    @action
    async pasteConfig(targetClientId) {
        if (!this.isConfigCopied) {
            return;
        }

        const copy = { ...this.copy };
        this.resetCopy();

        const { data } = await http.post(`/client/${targetClientId}/configuration/copy`, {
            sourceClient: copy.clientId,
            sourceConfiguration: copy.configId,
        });

        this.data = new Client(data);
    }

    @computed
    get isReportDocumentCopied() {
        return this.copy.reportDocumentId && this.copy.sourceClientId;
    }

    @action
    copyReportDocument(id) {
        this.resetCopy();
        this.copy.reportDocumentId = id;
        this.copy.sourceClientId = this.data._id;
    }

    @action
    async pasteReportDocument(targetClientId) {
        if (!this.isReportDocumentCopied) {
            return;
        }

        const copy = { ...this.copy };
        this.resetCopy();

        await http.post(
            `/client/${targetClientId}/report-documents/${copy.reportDocumentId}/copy`,
            { sourceClientId: copy.sourceClientId, isTemplate: true },
        );
    }
}

export default new ClientDetails();
