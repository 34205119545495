import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import BaseCard from "@app/components/card/card";
import { Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import "./styles/card.scoped.scss";

const Card = observer(({ entry, short, onClick, onEdit, onRemove, ...rest }) => {
    /**
     * Handle edit button click
     */
    const edit = (event) => {
        event.preventDefault();

        if (onEdit) {
            onEdit(entry, event);
        }
    };

    /**
     * Handle delete button click
     */
    const remove = (event) => {
        event.preventDefault();

        if (onRemove) {
            onRemove(entry, event);
        }
    };

    return (
        <BaseCard className={classNames("entry-card", { short })} onClick={onClick} {...rest}>
            <div className="name">{entry.name}</div>
            <div className="text">{entry.text}</div>

            <div className="actions">
                {onEdit && <Button onClick={edit} type="icon" icon={<EditOutlined />}></Button>}
                {onRemove && (
                    <Button onClick={remove} type="icon" danger icon={<DeleteOutlined />}></Button>
                )}
            </div>
        </BaseCard>
    );
});

export default Card;
