import React from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import format from "@app/lib/format";
import versionStore from "@app/state/store/report-document/version";
import versionAliasStore from "@app/state/store/report-document/version-alias";

import Title from "./title";
import VersionMenu from "./version-menu";

import "./version.scoped.scss";

const getVersionTitle = ({ title, versions, type }) => {
    if (type !== "revert") {
        return title;
    }

    if (title.includes("initial")) {
        return title;
    }

    const [splitTitle, revertId] = title.split("version ");
    const revertVersion = versions.find(({ _id }) => _id === revertId);

    if (revertVersion?.type !== "revert" && revertVersion?.title) {
        return `${splitTitle} (${revertVersion.title})`;
    }

    if (revertVersion?.updatedAt) {
        return splitTitle + " (" + format.datetime(revertVersion.updatedAt) + ")";
    }

    return title;
};

const Version = observer(
    ({
        updatedAt,
        createdBy,
        title,
        type,
        versionId,
        sectionIds = [],
        sectionMap = [],
        isLatestVersion,
        grouped,
    }) => {
        const sectionId = sectionIds.length === 1 ? sectionIds[0] : null;
        const history = useHistory();

        const onClick = () => {
            versionStore.setSelected(versionId || "initial");
            versionAliasStore.onSelectVersionAlias();

            if (sectionId) {
                history.push({
                    pathname: history.location.pathname,
                    search: `?section=${sectionId}`,
                });
            }
        };
        const section = sectionId ? sectionMap.find(({ value }) => value === sectionId) : {};
        const { label } = section || {};
        const subtitle = sectionId ? `Section: ${label}` : "Changes to multiple sections";

        const onCreateVersion = () => {
            versionAliasStore.onShowCreateVersionModal({ versionId });
        };

        const versions = versionStore.versions;
        const versionTitle = getVersionTitle({ title, versions, type });

        return (
            <li
                className={cn({
                    "version-list-item": true,
                    "single-entry": !grouped,
                    selected:
                        versionId === versionStore.versionId && versionStore.viewMode === "diff",
                })}
            >
                <div className="container" onClick={onClick}>
                    <Title
                        title={versionTitle}
                        updatedAt={updatedAt}
                        isLatestVersion={isLatestVersion}
                    />

                    <div>{subtitle}</div>

                    <div>
                        Edited by {createdBy} on {format.datetime(updatedAt)}
                    </div>
                </div>
                <VersionMenu onCreateVersion={() => onCreateVersion(versionId)} />
            </li>
        );
    },
);

export default Version;
