import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import { Page } from "@app/components/page";
import SearchOverview from "./search-overview";
import ReportDocumentsOverview from "./report-documents-overview";

import report from "@app/state/store/report";

import "./style/overview.scoped.scss";

const SEARCH_TAB = "search";
const REPORT_DOCUMENTS_TAB = "report documents";

const ProjectOverview = observer(() => {
    const { project } = useParams();
    const [activeKey, setActiveKey] = useState(SEARCH_TAB);

    const reportDocumentsEnabled = report.config?.reportDocuments?.enabled;

    useEffect(() => {
        if (report.config?.reportDocuments?.enabled) {
            setActiveKey(REPORT_DOCUMENTS_TAB);
        }
    }, [report.config?.reportDocuments?.enabled]);

    const switchTab = (key) => {
        setActiveKey(key);
    };

    return (
        <Page className="overview">
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title breadcrumbs={true}>Report Overview</Page.Title>
                </Page.Header.Left>
            </Page.Header>

            <Page.Body>
                <div className="container">
                    <Tabs activeKey={activeKey} onTabClick={switchTab} destroyInactiveTabPane>
                        {reportDocumentsEnabled && (
                            <Tabs.TabPane
                                key={REPORT_DOCUMENTS_TAB}
                                tab="Report Documents Overview"
                            >
                                <ReportDocumentsOverview project={project} />
                            </Tabs.TabPane>
                        )}

                        <Tabs.TabPane key={SEARCH_TAB} tab="Search Results Overview">
                            <SearchOverview />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </Page.Body>
        </Page>
    );
});

export default ProjectOverview;
