import { action, computed } from "mobx";
import { v4 as uuid } from "uuid";
import { Role } from "@app/constants";
import http from "@app/lib/http";
import User from "@app/state/model/user";
import BaseStore from "@app/state/store/base";

const STAGGER_ID = uuid();

/**
 * State management controlling the list of users in
 * the user management sections
 */
export class UserPicker extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            list: [],
            loading: false,
            search: null,
            role: undefined,
        };
    }

    /**
     * Return a filtered list
     */
    @computed
    get filtered() {
        if (!this.search) {
            return this.list;
        }

        let words = this.search.toLowerCase().split(/\s+/);

        return this.list.filter((user) => {
            let name = user.fullName.toLowerCase();
            let match = true;

            for (let word of words) {
                if (!name.includes(word)) {
                    match = false;
                    break;
                }
            }

            return match;
        });
    }

    /**
     * Load a list with users from the backend
     */
    @action
    async load(systemRole = Role.USER, args = {}) {
        const role = !Array.isArray(systemRole) ? [systemRole] : systemRole;
        this.loading = true;
        this.role = role;
        this.list = [];

        let filter = {
            status: ["active"],
            project: args.project,
        };

        // pass the client filter only for the client role
        if (role?.includes(Role.CLIENT)) {
            filter.client = args.client;
        }

        if (role) {
            filter.role = role;
        }

        let { data } = await http.get("/users", filter).stagger(STAGGER_ID);
        this.list = data.list.map((entry) => {
            return new User(entry);
        });

        this.loading = false;
    }
}

export default new UserPicker();
