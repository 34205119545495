import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import Avatar from "@app/components/user/avatar";
import format from "@app/lib/format";
import {
    Activities as ActivityType,
    DocumentStatus,
    ActivityLogView,
    CategoryStatus,
} from "@app/constants";
import BaseModal from "@app/components/modal";
import {
    Abstract,
    ScoringCriteria,
    Answer,
    DefaultView,
    AdditionalData,
    Question,
    AdditionalDataConfiguration,
    Comment,
    Search,
    Tag,
    Keyword,
    Reason,
    Note,
    Highlight,
    ReportDocumentSection,
    ReportDocumentSections,
    Reference,
    ReviewStage,
} from "./details";

import "./style/list.scoped.scss";

const MoreDetails = observer(({ activity, moreDetails }) => {
    let Component = DefaultView;
    let displayFlow = "column";

    if (
        activity.path === "article.review.l2.questions" ||
        activity.path === "review.l2.questions"
    ) {
        Component = Answer;
    } else if (activity.path === "additionalData") {
        Component = AdditionalData;
    } else if (activity.target?.type && activity.target?.type.indexOf("comment") !== -1) {
        Component = Comment;
    } else if (["slr.l2.questions", "sota.l2.questions"].includes(activity.path)) {
        Component = Question;
    } else if (activity.path === "slr.l2.criteria" || activity.path === "sota.l2.criteria") {
        Component = ScoringCriteria;
    } else if (["slr.additionalData", "sota.additionalData"].includes(activity.path)) {
        Component = AdditionalDataConfiguration;
    } else if (activity.path === "abstract") {
        Component = Abstract;
    } else if (activity.target?.type === ActivityType.SEARCH) {
        Component = Search;
    } else if (
        ["contentTags", "documentTags", "articleTags"].includes(activity.path) &&
        activity.target?.type === ActivityType.PROJECT_CONFIG
    ) {
        Component = Tag;
    } else if (["slr.keywords", "sota.keywords"].includes(activity.path)) {
        Component = Keyword;
    } else if (
        [
            "slr.l2.excludeReasons",
            "slr.l1.excludeReasons",
            "sota.l2.excludeReasons",
            "sota.l1.excludeReasons",
        ].includes(activity.path)
    ) {
        Component = Reason;
    } else if (activity.path === "note" && activity.target?.type === ActivityType.ARTICLE) {
        Component = Note;
    } else if (activity.target?.type === ActivityType.ARTICLE_HIGHLIGHT) {
        if (activity.path !== "content") {
            Component = Highlight;
        }
    } else if (activity.target?.type === ActivityType.REPORT_DOCUMENT) {
        displayFlow = "row";
        if (activity.keywords.includes("update-sections")) {
            Component = ReportDocumentSections;
        } else if (activity.path === "reviewConfig.stages") {
            Component = ReviewStage;
        }
    } else if (activity.target?.type === ActivityType.REPORT_DOCUMENT_SECTION) {
        displayFlow = "row";
        Component = ReportDocumentSection;
    } else if (activity.target?.type === ActivityType.CITATION) {
        displayFlow = "row";
        Component = Reference;
    } else if (activity.path === "review.stages") {
        displayFlow = "row";
        Component = ReviewStage;
    }

    return (
        <div className={`container ${displayFlow}`}>
            {moreDetails.title && <div className="title">{moreDetails.title}</div>}
            <>
                {moreDetails.initial !== undefined && moreDetails.initial !== null && (
                    <div className="old">
                        <span className="type">Old Value</span>
                        <Component data={moreDetails.initial} />
                    </div>
                )}
                {moreDetails.edited !== undefined && moreDetails.edited !== null && (
                    <div className="new">
                        <span className="type">New Value</span>
                        <Component data={moreDetails.edited} />
                    </div>
                )}
            </>
        </div>
    );
});

const Modal = observer(({ show, hideModal, activity, moreDetails }) => {
    return (
        <BaseModal
            title={moreDetails?.heading ?? "Details"}
            visible={show}
            width={800}
            onCancel={hideModal}
            cancelText="Close"
            okButtonProps={{ style: { display: "none" } }}
            keyboard={true}
        >
            <MoreDetails activity={activity} moreDetails={moreDetails} />
        </BaseModal>
    );
});

const MoreDetailsButton = observer(({ setShow, setCurrent, activity, moreDetails, setDetails }) => {
    return (
        <Button
            onClick={() => {
                setShow(true);
                setCurrent(activity);
                setDetails(moreDetails);
            }}
        >
            More Details
        </Button>
    );
});

const List = observer(({ activities, date, context, view }) => {
    const [show, setShow] = React.useState(false);
    const [current, setCurrent] = React.useState(false);
    const [details, setDetails] = React.useState(false);
    const hideModal = () => setShow(false);

    // The process function is responsible for preparing the activity description.
    // It takes an activity and checks the path, type & operation and prepares a meaningful context.
    // "moreDetails" object is returned for certain actions. This enabled the More Details buttons.
    // moreDetails has two keys, edited & initial contains the details to show the Old and New Value.

    const process = (activity, context) => {
        const { operation, newValue, oldValue, path, target, action, keywords, user } = activity;

        const message = [];
        let moreDetails = undefined;

        if (target?.type === ActivityType.PROJECT_CONFIG) {
            if (path === "contentTags") {
                const newVal = format.shorten(format.sanitize(newValue?.label));
                const old = format.shorten(format.sanitize(oldValue?.label));

                if (operation === "add") {
                    moreDetails = {
                        edited: newValue,
                        initial: undefined,
                        heading: "Content Tag",
                    };
                    message.push(`Content Tag <em>${newVal}</em> was added`);
                } else if (operation === "update") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Content Tag",
                    };
                    message.push(`Content Tag was updated`);
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: undefined,
                        initial: oldValue,
                        heading: "Content Tag",
                    };
                    message.push(`Content Tag <em>${old}</em> was removed`);
                }
            }
            // searchDb
            else if (
                [
                    "search.prospero",
                    "search.embase",
                    "search.eupmc",
                    "search.pubmed",
                    "search.google",
                    "search.cochrane",
                ].includes(path)
            ) {
                const db = format.searchDb(path.split(".")[1]);

                message.push(`${db} was ${newValue === true ? "enabled" : "disabled"}`);
            } else if (path === "slr.l2.requireAnswers") {
                message.push(
                    `DUE Require an answer for every question was ${
                        newValue === true ? "enabled" : "disabled"
                    }`,
                );
            } else if (path === "slr.l2.enableScore") {
                message.push(
                    `Score based article qualification was ${
                        newValue === true ? "enabled" : "disabled"
                    }`,
                );
            } else if (path === "slr.l2.excludeReasons") {
                const newVal = format.sanitize(newValue?.reason);
                const old = format.sanitize(oldValue?.reason);

                if (operation === "add") {
                    message.push(`DUE L2 Exclusion reason <em> ${newVal} </em> was added`);
                } else if (operation === "sort") {
                    message.push(`DUE L2 Exclusion reason sort order was updated`);
                } else if (operation === "update") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "DUE L2 Exclusion Reason",
                    };
                    message.push(`DUE L2 Exclusion reason was updated`);
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "DUE L2 Exclusion Reason",
                    };
                    message.push(`DUE L2 Exclusion reason <em> ${old} </em> was removed`);
                }
            } else if (path === "slr.l2.criteria") {
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "DUE Scoring Criteria",
                };
                message.push(`DUE scoring criteria was updated`);
            } else if (path === "slr.additionalData") {
                const newTitle = format.shorten(format.sanitize(newValue?.title));

                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "DUE Data Extraction Field",
                };

                if (operation === "add") {
                    message.push(`DUE Data extraction field title <em> ${newTitle}</em> was added`);
                } else if (operation === "update") {
                    message.push(`DUE Data extraction field title was updated`);
                } else if (operation === "remove") {
                    message.push(`DUE Data extraction field title was removed`);
                }
            } else if (path === "slr.l1.excludeReasons") {
                const newVal = format.sanitize(newValue?.reason);
                const old = format.sanitize(oldValue?.reason);
                if (operation === "add") {
                    message.push(`DUE L1 Exclusion reason <em> ${newVal}</em> was added`);
                } else if (operation === "sort") {
                    // No other information is available.
                    message.push(`DUE L1 Exclusion reason sort order was updated`);
                } else if (operation === "update") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "DUE L1 Exclusion Reason",
                    };
                    message.push(`DUE L1 Exclusion reason was updated`);
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "DUE L1 Exclusion Reason",
                    };
                    message.push(`DUE L1 Exclusion reason <em> ${old} </em> was removed`);
                }
            } else if (path === "slr.keywords") {
                const newType = newValue?.positive === true ? "Positive" : "Negative";
                const newVal = format.shorten(format.sanitize(newValue?.label));

                const old = format.shorten(format.sanitize(oldValue?.label));
                const oldType = oldValue?.positive === true ? "Positive" : "Negative";

                const edited = `${newType} DUE keyword`;

                if (operation === "add") {
                    message.push(`${edited} <em>${newVal} </em> was added`);
                } else if (operation === "update") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "DUE Keyword",
                    };
                    message.push(`DUE keyword was updated`);
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: undefined,
                        initial: oldValue,
                        heading: "DUE Keyword",
                    };
                    message.push(`${oldType} DUE keyword <em> ${old}</em> was removed`);
                }
            } else if (path === "slr.l2.questions") {
                moreDetails = {
                    initial: oldValue,
                    edited: newValue,
                    heading: "DUE Question",
                };

                if (operation === "add") {
                    message.push(`DUE question was added`);
                } else if (operation === "update") {
                    message.push(`DUE question was updated`);
                } else if (operation === "remove") {
                    message.push(`DUE question was removed`);
                }
            } else if (path === "sota.workflowEnabled") {
                message.push(`SoTA workflow was ${newValue === true ? "enabled" : "disabled"}`);
            } else if (path === "sota.enabled") {
                message.push(`SoTA was ${newValue === true ? "enabled" : "disabled"}`); //
            } else if (path === "sota.keywords") {
                const newType = newValue?.positive === true ? "Positive" : "Negative";
                const newVal = format.shorten(format.sanitize(newValue?.label));

                const old = format.shorten(format.sanitize(oldValue?.label));
                const oldType = oldValue?.positive === true ? "Positive" : "Negative";

                const edited = `${newType} SoTA keyword`;

                if (operation === "add") {
                    message.push(`${edited} <em>${newVal} </em> was added`);
                } else if (operation === "update") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "SoTA Keyword",
                    };
                    message.push(`SoTA keyword was updated </em>`);
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: undefined,
                        initial: oldValue,
                        heading: "SoTA Keyword",
                    };
                    message.push(`${oldType} SoTA keyword <em> ${old}</em> was removed`);
                }
            } else if (path === "sota.l2.questions") {
                const newVal = format.sanitize(newValue?.question);
                const old = format.sanitize(oldValue?.question);
                moreDetails = {
                    initial: oldValue,
                    edited: newValue,
                    heading: "SoTA Question",
                };

                if (operation === "add") {
                    message.push(`SoTA question <em> ${newVal} </em> was added`);
                } else if (operation === "update") {
                    message.push(`SoTA question <em> ${newVal} </em> was updated`);
                } else if (operation === "remove") {
                    message.push(`SoTA question <em> ${old} </em> was removed`);
                }
            } else if (path === "sota.l1.excludeReasons") {
                const newVal = format.shorten(format.sanitize(newValue?.reason));
                const old = format.shorten(format.sanitize(oldValue?.reason));
                if (operation === "add") {
                    message.push(`SoTA L1 Exclusion reason <em > ${newVal} was added</em>`);
                } else if (operation === "sort") {
                    message.push(`SoTA L1 Exclusion reason sort order was updated`);
                } else if (operation === "update") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "SoTA L1 Exclusion Reason",
                    };
                    message.push(`SoTA L1 Exclusion reason was updated`);
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "SoTA L1 Exclusion Reason",
                    };
                    message.push(`SoTA L1 Exclusion reason <em > ${old} was removed</em>`);
                }
            } else if (path === "sota.l2.requireAnswers") {
                message.push(
                    `SoTA Require an answer for every questions was ${
                        newValue === true ? "enabled" : "disabled"
                    }`,
                );
            } else if (path === "sota.additionalData") {
                const newTitle = format.shorten(format.sanitize(newValue?.title));

                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "SoTA Data Extraction Field",
                };

                if (operation === "add") {
                    message.push(
                        `SoTA Data extraction field title <em> ${newTitle} </em> was added`,
                    );
                } else if (operation === "update") {
                    message.push(`SoTA Data extraction field title was updated`);
                } else if (operation === "remove") {
                    message.push(`SoTA Data extraction field title was removed`);
                }
            } else if (path === "sota.l2.excludeReasons") {
                const newVal = format.sanitize(newValue?.reason);
                const old = format.sanitize(oldValue?.reason);

                if (operation === "add") {
                    message.push(`SoTA L2 Exclusion reason <em> ${newVal} </em> was added`);
                } else if (operation === "sort") {
                    message.push(`SoTA L2 Exclusion reason sort order was updated`);
                } else if (operation === "update") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "SoTA L2 Exclusion Reason",
                    };
                    message.push(`SoTA L2 Exclusion reason was updated`);
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "SoTA L2 Exclusion Reason",
                    };
                    message.push(`SoTA L2 Exclusion reason <em> ${old} </em> was removed`);
                }
            } else if (path === "sota.l2.enableScore") {
                message.push(
                    `Score based article qualification was ${
                        newValue === true ? "enabled" : "disabled"
                    }`,
                );
            } else if (path === "sota.l2.criteria") {
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "SOTA Scoring Criteria",
                };
                message.push(`SOTA scoring criteria was updated`);
            } else if (path === "risk.enabled") {
                message.push(`Risk Analysis was ${newValue === true ? "enabled" : "disabled"}`);
            } else if (path === "risk.productCodes") {
                const newVal = format.sanitize(newValue);
                const old = format.sanitize(oldValue);

                if (operation === "add") {
                    message.push(`Risk code ${newVal} was added`);
                } else if (operation === "update") {
                    message.push(`Risk code was updated from ${old} to ${newVal}`);
                } else if (operation === "remove") {
                    message.push(`Risk code ${old} was removed`);
                }
            } else if (path === "risk.startDate") {
                const newDate = format.date(format.sanitize(newValue));
                const oldDate = format.date(format.sanitize(oldValue));

                if (operation === "add") {
                    message.push(`Risk Start Date ${newDate} was added`);
                } else if (operation === "update") {
                    message.push(
                        `Risk Start Date was updated from <em>${oldDate}</em> to <em>${newDate}</em>`,
                    );
                }
            } else if (path === "risk.endDate") {
                const newDate = format.date(format.sanitize(newValue));
                const oldDate = format.date(format.sanitize(oldValue));

                if (operation === "add") {
                    message.push(`Risk End Date ${newDate} was added`);
                } else if (operation === "update") {
                    message.push(
                        `Risk End Date was updated from <em>${oldDate}</em> to <em>${newDate}</em>`,
                    );
                }
            } else if (path === "sourceDocuments.enabled") {
                if (operation === "update") {
                    message.push(
                        `Source Documents was ${newValue === true ? "enabled" : "disabled"}`,
                    );
                }
            } else if (path === "documentTags") {
                const newVal = format.shorten(format.sanitize(newValue?.label));
                const old = format.shorten(format.sanitize(oldValue?.label));

                if (action === "update") {
                    if (operation === "add") {
                        moreDetails = {
                            edited: newValue,
                            initial: undefined,
                            heading: "Document Tag",
                        };
                        message.push(`Document Tag <em>${newVal}</em> tag was added`);
                    } else if (operation === "update") {
                        moreDetails = {
                            edited: newValue,
                            initial: oldValue,
                            heading: "Document Tag",
                        };
                        message.push(`Document Tag <em>${newVal}</em> was updated`);
                    } else if (operation === "remove") {
                        moreDetails = {
                            edited: newValue,
                            initial: undefined,
                            heading: "Document Tag",
                        };
                        message.push(`Document Tag <em>${old}</em> was removed`);
                    }
                }
            } else if (path === "articleTags") {
                const old = format.shorten(format.sanitize(oldValue?.label));
                const newVal = format.shorten(format.sanitize(newValue?.label));

                if (operation === "add") {
                    moreDetails = {
                        edited: newValue,
                        initial: undefined,
                        heading: "Article Tag",
                    };
                    message.push(`Article Tag <em >${newVal}</em> was added`);
                } else if (operation === "update") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Article Tag",
                    };
                    message.push(`Article Tag <em>${old}</em> was updated`);
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: undefined,
                        initial: oldValue,
                        heading: "Article Tag",
                    };
                    message.push(`Article Tag <em>${old}</em> was removed`);
                }
            } else if (path === "review.stages") {
                if (operation === "add") {
                    message.push(`Review stage ${newValue?.title} was added`);
                } else if (operation === "update") {
                    message.push(`Review stage ${oldValue?.title} was updated`);
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Review Stage",
                    };
                } else if (operation === "remove") {
                    message.push(`Review stage ${oldValue?.title} was removed`);
                }
            } else if (path === "reference.style") {
                if (operation === "update") {
                    message.push(`Reference style was updated`);
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Reference Style",
                    };
                }
            } else if (path === "reportDocuments.enabled") {
                message.push(`Report documents is ${newValue ? "enabled" : "disabled"} `);
            } else if (path === "reports.slr" || path === "reports.sota") {
                const type = path === "reports.slr" ? "DUE" : "SOTA";
                if (operation === "add") {
                    message.push(`${type} Report - ${newValue.reportName} was added`);
                } else if (operation === "remove") {
                    message.push(`${type} Report - ${oldValue.reportName} was removed`);
                }
            } else if (path === "reports.slr.fields" || path === "reports.sota.fields") {
                const type = path === "reports.slr.fields" ? "DUE" : "SOTA";
                const reportName = keywords.find((k) => k.includes("Report: ")) || "Report";

                if (operation === "add") {
                    message.push(`${type} - ${reportName} ${newValue.label} field was added`);
                } else if (operation === "remove") {
                    message.push(`${type} - ${reportName} ${oldValue.label} field was removed`);
                } else if (operation === "update") {
                    message.push(`${type} - ${reportName} field was updated`);
                    moreDetails = {
                        edited:
                            newValue.fieldType === "multi-select"
                                ? newValue.values.map((v) => v.label).join(", ")
                                : newValue.value,
                        initial:
                            oldValue.fieldType === "multi-select"
                                ? oldValue.values.map((v) => v.label).join(", ")
                                : oldValue.value,
                        heading: newValue.label,
                    };
                }
            }
        } else if (target?.type === ActivityType.DOCUMENT_CATEGORY) {
            const newVal = format.shorten(format.sanitize(target?.name));
            const old = format.shorten(format.sanitize(oldValue?.name));
            if (path === "enabled") {
                if (action === "update") {
                    const status = newValue === true ? "enabled" : "disabled";
                    message.push(`Document Category <em>${newVal}</em> was ${status}`);
                }
            } else if (path === "status") {
                const categoryName = target?.name;

                const oldStatus = format.properCase(format.sanitize(oldValue));

                if (newValue === CategoryStatus.APPROVED && oldValue === CategoryStatus.PENDING) {
                    message.push(
                        `${categoryName} Category status was updated from ${oldStatus} to <em class="approved">${format.properCase(
                            newValue,
                        )}</em>`,
                    );
                } else if (
                    newValue === CategoryStatus.PENDING &&
                    oldValue === CategoryStatus.APPROVED
                ) {
                    message.push(
                        `${categoryName} Category status was updated from ${oldStatus} to <em class="pending">${format.properCase(
                            newValue,
                        )}</em>`,
                    );
                }
            } else if (path === "name") {
                if (action === "update") {
                    moreDetails = {
                        edited: newVal,
                        initial: oldValue,
                        heading: "Document Category",
                    };

                    message.push(`Document category was updated`);
                }
            } else if (action === "create") {
                message.push(`Document category <em>${newVal}</em> was added`);
            } else if (action === "delete") {
                message.push(`Document category <em>${old}</em> was removed`);
            }
        }
        //end of project configuration logs

        //Project Overview Starts
        else if (target?.type === ActivityType.PROJECT) {
            if (path === "name") {
                const newVal = format.shorten(format.sanitize(newValue));
                const old = format.shorten(format.sanitize(oldValue));

                if (action === "update") {
                    moreDetails = {
                        edited: newVal,
                        initial: old,
                        heading: "Project Name",
                    };
                    message.push(`Project renamed as <em>${newVal}</em>`);
                }
            } else if (path === "users") {
                let projectRole = "",
                    user = "";

                if (operation === "add" || operation === "update") {
                    if (newValue?.length > 0) {
                        user = newValue[0].user;
                        projectRole = format.sanitize(newValue[0].role);
                    }

                    message.push(
                        `<em class="author">${format.shorten(
                            format.sanitize(user?.fullName),
                        )}</em> was added as ${format.projectRoles(projectRole)}`,
                    );
                } else if (operation === "remove") {
                    user = oldValue.user;
                    projectRole = format.sanitize(oldValue.role);

                    message.push(
                        `<em class="author">${format.shorten(
                            user?.fullName,
                        )}</em> was removed as ${format.projectRoles(projectRole)}`,
                    );
                }
            } else if (path === "status") {
                if (action === "update") {
                    if (oldValue && newValue) {
                        message.push(
                            `Project Status was changed from <em>${format.properCase(
                                format.sanitize(oldValue),
                            )} </em> to <em>${format.properCase(format.sanitize(newValue))}</em>`,
                        );
                    }
                }
            } else if (path === "endDate") {
                const newDate = format.date(format.sanitize(newValue));
                const oldDate = format.date(format.sanitize(oldValue));

                if (operation === "update") {
                    message.push(
                        `Project End Date was updated from <em> ${oldDate}</em> to <em> ${newDate}</em>`,
                    );
                } else if (operation === "remove") {
                    message.push(`Project End Date <em> ${oldDate}</em> was removed`);
                } else if (operation === "add") {
                    message.push(`Project End Date <em> ${newDate}</em> was added`);
                }
            } else if (path === "description") {
                const newVal = format.shorten(format.sanitize(newValue));
                const old = format.shorten(format.sanitize(oldValue));
                moreDetails = {
                    edited: newVal,
                    initial: old,
                    heading: "Project Description",
                };
                message.push(`Project description was updated`);
            } else if (action === "create") {
                message.push(
                    `Project ${format.shorten(format.sanitize(target?.name))} was created`,
                );
            }
        } else if (target?.type === ActivityType.PROJECT_COMMENT) {
            moreDetails = {
                edited: newValue,
                initial: oldValue,
                heading: "Project Comment",
            };

            if (action === "create") {
                message.push(`Project Comment was added`);
            } else if (action === "update") {
                message.push(`Project Comment was updated`);
            } else if (action === "delete") {
                message.push(`Project Comment was removed`);
            }
        }

        // Search Related
        else if (target?.type === ActivityType.SEARCH) {
            let db = format.searchDb(format.sanitize(newValue?.source));
            const type = format.searchType(format.sanitize(newValue?.type));
            if (newValue?.source === "other") {
                db = "Import";
            }

            const newSearchQuery = format.shorten(format.sanitize(newValue?.query));

            if (action === "create") {
                moreDetails = {
                    edited: newValue,
                    heading: "Search",
                };
                const searchName = format.shorten(format.sanitize(newValue?.name));

                message.push(`${searchName} :: ${db} [${type}] was searched for ${newSearchQuery}`);
            } else if (action === "save") {
                message.push(`${target?.name} was saved`);
            } else if (action === "update") {
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "Search",
                };
                message.push(`${target?.name} was updated as : ${db} [${type}] ${newSearchQuery}`);
            } else if (action === "delete") {
                message.push(`${target?.name} was removed`);
            }
        } else if (target.type === ActivityType.SEARCH_COMMENT) {
            moreDetails = {
                edited: newValue,
                initial: oldValue,
                heading: "Search Comment",
            };

            if (action === "create") {
                message.push(`Comment was added`);
            } else if (action === "update") {
                message.push(`Comment was updated`);
            } else if (action === "delete") {
                message.push(`Comment was removed`);
            }
        }

        // Article Related
        else if (target?.type === ActivityType.ARTICLE) {
            if (path === "review") {
                message.push(`Review Status Reset`);
            } else if (path === "file") {
                if (operation === "add") {
                    message.push(`Full text was uploaded`);
                } else if (operation === "remove") {
                    message.push(`Full text was removed`);
                } else if (operation === "update") {
                    message.push(`Full text was updated`);
                }
            } else if (path === "note") {
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "Notes",
                };

                if (operation === "add") {
                    // First time adding a note to the article
                    moreDetails.initial = undefined;
                    message.push(`Note was added`);
                } else if (operation === "update") {
                    if (newValue === "") {
                        moreDetails.edited = undefined;
                        message.push(`Note was removed`);
                    } else if (oldValue === "") {
                        moreDetails.initial = undefined;
                        // Previously there was an old note with value as "".
                        message.push(`Note was added`);
                    } else {
                        message.push(`Note was updated`);
                    }
                }
            } else if (action === "exclude") {
                // L1 Or L2 Exclude

                const stage = newValue?.stage === "l1" ? " L1 Screening" : "L2 Review";
                const reason = format.shorten(newValue?.reason);
                const old = format.sanitize(oldValue?.reason);

                moreDetails = {
                    edited: `Excluded at ${stage} ${format.sanitize(newValue?.reason)}`,
                    initial: old,
                    heading: "Exclusion Reasons",
                };

                message.push(`<em class="excluded">Excluded</em> at ${stage} (${reason})`);
            } else if (path === "tags") {
                const old = format.shorten(format.sanitize(oldValue));
                const newVal = format.shorten(format.sanitize(newValue));

                if (operation === "remove") {
                    message.push(`Highlight <em>${old}</em> was removed`);
                } else if (operation === "add") {
                    message.push(`Highlight <em>${newVal}</em> was added`);
                }
            } else if (path === "articleTags") {
                const old = format.shorten(format.sanitize(oldValue?.label));
                const newVal = format.shorten(format.sanitize(newValue?.label));

                if (operation === "remove") {
                    moreDetails = {
                        edited: newVal,
                        initial: old,
                        heading: "Tag",
                    };
                    message.push(`Tag <em >${old}</em> was removed`);
                } else if (operation === "add") {
                    moreDetails = {
                        edited: newVal,
                        initial: old,
                        heading: "Tag",
                    };
                    message.push(`Tag <em>${newVal}</em> was added`);
                } else if (operation === "update") {
                    moreDetails = {
                        edited: newVal,
                        initial: old,
                        heading: "Tag",
                    };
                    message.push(`Tag was updated`);
                }
            } else if (path === "article.review.l1.status") {
                if (oldValue && !newValue) {
                    message.push(`L1 Screening status was reset`);
                }
            } else if (path === "review.l2.started") {
                if (newValue === false && oldValue === true) {
                    message.push(`L2 Review was cancelled`);
                } else if (newValue === true && oldValue === false) {
                    message.push(`L2 Review was started`);
                } else if (newValue === true) {
                    message.push(`L2 Review started`);
                }
            } else if (path === "article.review.l2.questions" || path === "review.l2.questions") {
                if (operation === "update" && newValue && oldValue) {
                    const question = format.shorten(format.sanitize(newValue.question));

                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Answer",
                    };

                    if (!oldValue?.answer?.answer) {
                        moreDetails.initial = undefined;
                        message.push(`${question} was answered`);
                    } else {
                        message.push(`${question} answer was updated`);
                    }
                } else if (operation === "add") {
                    const question = format.shorten(format.sanitize(newValue.question));
                    message.push(`${question} was added`);
                } else if (operation === "remove") {
                    const question = format.shorten(format.sanitize(oldValue.question));
                    message.push(`${question} was removed`);
                }
            } else if (path === "review.l2.complete") {
                if (newValue === true) {
                    message.push(`L2 Review was completed`);
                }
            } else if (path === "article.review.l2.status") {
                if (oldValue && !newValue) {
                    message.push(`L2 Review status was reset`);
                }
            } else if (path === "abstract") {
                if (newValue === "") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Abstract",
                    };
                    message.push(`Abstract was removed`);
                } else {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Abstract",
                    };
                    message.push(`Abstract was updated`);
                }
            } else if (path === "authors") {
                const newName = `${format.sanitize(newValue?.firstName)} ${format.sanitize(
                    newValue?.lastName,
                )}`;
                const oldName = `${format.sanitize(oldValue?.firstName)} ${format.sanitize(
                    oldValue?.lastName,
                )}`;

                if (operation === "add") {
                    message.push(`Author <em class="author"> ${newName}</em> was added`);
                } else if (operation === "remove") {
                    message.push(`Author <em class="author"> ${oldName}</em> was removed`);
                } else if (operation === "update") {
                    moreDetails = {
                        edited: newName,
                        initial: oldName,
                        heading: "Author",
                    };
                    message.push(`Author name was updated`);
                }
            } else if (path === "devices") {
                const newVal = format.sanitize(newValue);
                // When a device Manufacturer is removed, we get two events.
                if (operation === "add") {
                    if (newValue !== "") {
                        message.push(`Device <em> ${newVal}</em> was added`);
                    } else if (newValue === "") {
                        message.push(`Device was removed`);
                    }
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: newVal,
                        initial: undefined,
                        heading: "Device",
                    };
                    // Triggers when device Manufacturer keywords are modified.
                    message.push(`Device was removed`);
                }
            } else if (path === "descriptors") {
                const newVal = format.shorten(format.sanitize(newValue));
                const old = format.shorten(format.sanitize(oldValue));

                if (operation === "add") {
                    message.push(`Descriptor ${newVal}</em> were added`);
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: undefined,
                        initial: oldValue,
                        heading: "Descriptor",
                    };
                    message.push(`Descriptor ${old}</em> were removed`);
                }
            } else if (path === "link") {
                const old = format.shorten(format.sanitize(oldValue));
                const newVal = format.shorten(format.sanitize(newValue));
                if (newValue === "") {
                    moreDetails = {
                        edited: undefined,
                        initial: old,
                        heading: "Link",
                    };
                    message.push(`Link was removed`);
                } else {
                    moreDetails = {
                        edited: newVal,
                        initial: old,
                        heading: "Link",
                    };
                    message.push(`Link was updated`);
                }
            } else if (path === "keywords") {
                const newVal = format.shorten(format.sanitize(newValue));
                const old = format.shorten(format.sanitize(oldValue));
                if (operation === "add") {
                    message.push(`Author Keyword <em> ${newVal}</em> was added`);
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: undefined,
                        initial: old,
                        heading: "Author Keyword",
                    };
                    message.push(`Author Keyword<em> ${old}</em> was removed`);
                }
            } else if (path === "title") {
                if (operation === "update") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Title",
                    };
                    message.push(`Title was updated`);
                }
            } else if (path === "ids.doi") {
                if (operation === "update") {
                    if (newValue === "") {
                        message.push(`DOI <em>${format.sanitize(oldValue)}</em> was removed`);
                    } else if (oldValue === "") {
                        message.push(`DOI <em>${format.sanitize(newValue)}</em> was added`);
                    } else {
                        moreDetails = {
                            heading: "DOI",
                            edited: newValue,
                            initial: oldValue,
                        };

                        message.push(`DOI Id was updated`);
                    }
                }
            } else if (path === "ids.pubmed") {
                if (operation === "update") {
                    if (newValue === "") {
                        message.push(`PubMed Id <em>${format.sanitize(oldValue)}</em> was removed`);
                    } else if (oldValue === "") {
                        message.push(`PubMed Id <em>${format.sanitize(newValue)}</em> was added`);
                    } else {
                        moreDetails = {
                            heading: "PubMed",
                            edited: newValue,
                            initial: oldValue,
                        };

                        message.push(`PubMed Id was updated`);
                    }
                }
            } else if (path === "additionalData") {
                if (operation === "add") {
                    moreDetails = {
                        edited: newValue,
                        initial: undefined,
                        heading: "Data Extraction Fields",
                    };
                    message.push(
                        `Data Extraction <em > ${format.shorten(
                            format.sanitize(newValue?.title),
                        )} :  ${format.shorten(format.sanitize(newValue?.value))} </em> was added`,
                    );
                } else if (operation === "update") {
                    let edited = newValue;
                    let initial = oldValue;

                    if (keywords.includes("suggestion")) {
                        message.push(`AI suggestion was generated`);
                    } else if (keywords.includes("handled-suggestion")) {
                        message.push(`AI suggestion was considered`);
                        initial = undefined;
                    } else {
                        message.push(`Data Extraction Fields was updated`);
                    }

                    moreDetails = {
                        edited,
                        initial,
                        heading: "Data Extraction Fields",
                    };
                } else if (operation === "remove") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Data Extraction Fields",
                    };
                    message.push(`Data Extraction Field was removed`);
                }
            } else if (path === "publicationYear") {
                const newVal = format.sanitize(newValue);
                const old = format.sanitize(oldValue);
                if (operation === "update") {
                    message.push(
                        `Publication Year was updated from <em> ${old}</em> to <em> ${newVal}</em>`,
                    );
                } else if (operation === "remove") {
                    message.push(`Publication Year <em> ${old}</em> was removed`);
                } else if (operation === "add") {
                    message.push(`Publication Year <em> ${newVal}</em> was added`);
                }
            } else if (path === "publicationMonth") {
                const newVal = format.parseMonth(format.sanitize(newValue));
                const old = format.parseMonth(format.sanitize(oldValue));

                if (operation === "update") {
                    message.push(
                        `Publication Month was updated from <em> ${old}</em> to <em> ${newVal}</em>`,
                    );
                } else if (operation === "remove") {
                    message.push(`Publication Month <em> ${old}</em> was removed`);
                } else if (operation === "add") {
                    message.push(`Publication Month <em > ${newVal}</em> was added`);
                }
            } else if (path === "publicationDate") {
                const newDate = format.date(format.sanitize(newValue));
                const oldDate = format.date(format.sanitize(oldValue));

                if (operation === "update") {
                    if (!newValue) {
                        message.push(`Publication Date <em> ${oldDate}</em> was removed`);
                    } else if (!oldValue) {
                        message.push(`Publication Date was updated to <em> ${newDate}</em>`);
                    } else {
                        message.push(
                            `Publication Date was updated from <em> ${oldDate}</em> to <em> ${newDate}</em>`,
                        );
                    }
                } else if (operation === "add") {
                    message.push(`Publication Date <em> ${newDate}</em> was added`);
                }
            } else if (path === "pageNumbers") {
                const newVal = format.sanitize(newValue);
                const old = format.sanitize(oldValue);

                if (operation === "update") {
                    // No add or remove Operation
                    if (newValue === "") {
                        message.push(`Page Number <em > ${old}</em> was removed`);
                    }
                    message.push(
                        `Page Number was updated from <em> ${old}</em> to <em> ${newVal}</em>`,
                    );
                }
            } else if (path === "journalIssue") {
                const newVal = format.sanitize(newValue);
                const old = format.sanitize(oldValue);

                if (operation === "update") {
                    // No add or remove Operation
                    if (newValue === "") {
                        message.push(`Journal Issue <em> ${old}</em> was removed`);
                    }
                    message.push(
                        `Journal Issue was updated from <em> ${old}</em> to <em> ${newVal}</em>`,
                    );
                }
            } else if (path === "journalVolume") {
                const newVal = format.sanitize(newValue);
                const old = format.sanitize(oldValue);

                if (operation === "update") {
                    // No add or remove Operation
                    // Creates Additional Operations.
                    if (newValue === "") {
                        message.push(`Journal Volume <em > ${old}</em> was removed`);
                    }
                    message.push(
                        `Journal Volume was updated from <em> ${old}</em> to  <em> ${newVal}</em>`,
                    );
                }
            } else if (path === "journalName") {
                const newVal = format.sanitize(newValue);
                const old = format.sanitize(oldValue);

                if (operation === "update") {
                    // No add or remove Operation

                    if (newValue === "") {
                        message.push(`Journal Name <em > ${old}</em> was removed`);
                    }
                    message.push(
                        `Journal Name was updated from <em> ${old}</em> to  <em> ${newVal}</em>`,
                    );
                }
            } else if (path === "publicationCountry") {
                const newVal = format.sanitize(newValue);
                const old = format.sanitize(oldValue);

                if (operation === "update") {
                    // No add or remove Operation

                    if (newValue === "") {
                        message.push(`Publication Country <em> ${old}</em> was removed`);
                    }
                    message.push(
                        `Publication Country was updated from <em> ${old}</em> to <em> ${newVal}</em>`,
                    );
                }
            } else if (path === "article.crossChannel") {
                if (operation === "update") {
                    message.push(`Moved to another channel`);
                }
            } else if (action === "include") {
                // L1 Or L2 Include
                const stage = newValue?.stage === "l1" ? " L1 Screening" : "L2 Review";

                message.push(`<em class="included">Included</em> at ${stage}`);
            } else if (action === "create") {
                message.push("Article added");
            } else if (action === "delete") {
                message.push("Article deleted");
            } else if (action === "restore") {
                message.push("Article restore");
            } else if (action === "update") {
                // handle generic article updates
                if (operation === "original") {
                    message.push("Article set as original");
                }
            }
        } else if (target.type === ActivityType.ARTICLE_COMMENT) {
            moreDetails = {
                edited: newValue,
                initial: oldValue,
                heading: "Article Comment",
            };

            if (action === "create") {
                message.push(`Comment was added`);
            } else if (action === "update") {
                message.push(`Comment was updated`);
            } else if (action === "delete") {
                message.push(`Comment was removed`);
            }
        } else if (target?.type === ActivityType.ARTICLE_HIGHLIGHT) {
            if (path === "tags") {
                if (operation === "remove") {
                    message.push(`Highlight Tag <em>${format.sanitize(oldValue)}</em> was removed`);
                } else if (operation === "add") {
                    message.push(`Highlight Tag <em>${format.sanitize(newValue)}</em> was added`);
                }
            } else if (action === "create") {
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "Article Highlight",
                };
                message.push(`Highlight was added`);
            } else if (action === "delete") {
                moreDetails = {
                    edited: undefined,
                    initial: oldValue,
                    heading: "Article Highlight",
                };
                message.push(`Highlight was removed`);
            } else if (action === "update") {
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "Article Highlight",
                };
                message.push(`Highlight was updated`);
            }
        }

        // Start of Documents
        else if (target?.type === ActivityType.DOCUMENT) {
            const docName = format.shorten(format.sanitize(activity.document?.name));

            if (action === "version") {
                message.push(`Version ${newValue?.version} was uploaded`);
            } else if (action === "restore") {
                message.push(
                    `Restored to version from ${oldValue?.version} to ${newValue?.version}`,
                );
            } else if (path === "status") {
                if (newValue === DocumentStatus.ACCEPTED) {
                    message.push(`Document status changed to <em class="accepted">Accepted</em>`);
                } else if (newValue === DocumentStatus.PENDING) {
                    message.push(`Document status changed to <em>Pending</em>`);
                } else if (newValue === DocumentStatus.REJECTED) {
                    message.push(`Document status changed to <em class="rejected">Rejected</em>`);
                }
            } else if (path === "documentTags") {
                const newVal = format.shorten(format.sanitize(newValue?.label));
                const old = format.shorten(format.sanitize(oldValue?.label));

                if (action === "update") {
                    if (operation === "add") {
                        message.push(`Document Tag <em>${newVal}</em> was added`);
                    } else if (operation === "update") {
                        moreDetails = {
                            edited: newVal,
                            initial: old,
                            heading: "Tag",
                        };
                        message.push(`Document Tag was updated`);
                    } else if (operation === "remove") {
                        moreDetails = {
                            edited: newVal,
                            initial: old,
                            heading: "Tag",
                        };
                        message.push(`Document Tag <em>${old}</em> was removed`);
                    }
                }
            } else if (path === "categories") {
                if (operation === "add") {
                    // Category ID is captured as newValue instead of Category Name.
                    // Hence we are not able to show Category Name
                    message.push(`New category was added`);
                } else if (operation === "remove") {
                    message.push(`Category was removed`);
                }
            } else if (path === "name") {
                if (action === "update") {
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Document Name",
                    };
                    message.push(`Document renamed as <em>${docName}</em>`);
                }
            } else if (path === "preview" || path === "preview._id") {
                message.push(`Preview was uploaded for`);
            } else if (action === "create") {
                // document Uploaded
                if (activity.document?.name) {
                    message.push(`Document was uploaded`);
                }
            } else if (action === "delete") {
                message.push(`Document was removed`);
            }
        } else if (target?.type === ActivityType.DOCUMENT_COMMENT) {
            moreDetails = {
                edited: newValue,
                initial: oldValue,
                heading: "Document Comment",
            };
            if (action === "create") {
                message.push(`Comment was added`);
            } else if (action === "update") {
                message.push(`Comment was updated`);
            } else if (action === "delete") {
                message.push(`Comment was removed`);
            }
        } else if (target?.type === ActivityType.DOCUMENT_BOOKMARK) {
            const bookmarkName = format.sanitize(target?.name);
            const oldBookmarkName = format.sanitize(oldValue?.name);
            if (action === "create") {
                message.push(`Bookmark ${bookmarkName} was added`);
            } else if (action === "update") {
                message.push(`Bookmark ${bookmarkName} was updated`);
            } else if (action === "delete") {
                message.push(`Bookmark ${oldBookmarkName} was removed`);
            }
        } else if (target?.type === ActivityType.REPORT_DOCUMENT) {
            const name = target.name;
            if (action === "create") {
                message.push(`Report Document ${name} was added`);
            } else if (action === "update") {
                if (keywords.includes("update-sections")) {
                    message.push(`Report Document ${name} sections were updated`);
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: "Report Document Sections",
                    };
                } else if (path === "reviewConfig.stages" || path === "review.stage") {
                    let msg = `Report Document ${name} review process was updated. `;

                    if (operation === "add") {
                        msg += `Review stage <b>${newValue?.title}</b> was added`;
                    } else if (operation === "update") {
                        msg += `Review stage <b>${newValue?.title}</b> was updated`;
                        moreDetails = {
                            edited: newValue,
                            initial: oldValue,
                            heading: "Review Stage",
                        };
                    } else if (operation === "remove") {
                        msg += `Review stage <b>${oldValue?.title}</b> was removed`;
                    }

                    message.push(msg);
                } else {
                    message.push(`Report Document ${name} was updated`);
                    const property = path
                        .split(/[.]|(?=[A-Z])/)
                        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
                        .join(" ");
                    moreDetails = {
                        edited: newValue,
                        initial: oldValue,
                        heading: property,
                    };
                }
            } else if (action === "delete") {
                message.push(`Report Document ${name} was removed`);
            }
        } else if (target?.type === ActivityType.REPORT_DOCUMENT_SECTION) {
            const name = target.name;
            if (action === "create") {
                message.push(`Section ${name} was added`);
            } else if (action === "update") {
                message.push(`Section ${name} was updated by ${user?.fullName}`);
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "Report Document Section",
                };
            } else if (action === "delete") {
                message.push(`Section ${name} was removed`);
            }
        } else if (target?.type === ActivityType.DICTIONARY) {
            const name = target.name;
            if (action === "create") {
                message.push(`Dictionary ${name} was added`);
            } else if (action === "update") {
                message.push(`Dictionary ${name} was updated`);
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "Dictionary",
                };
            } else if (action === "delete") {
                message.push(`Dictionary ${name} was removed`);
            }
        } else if (target?.type === ActivityType.ABBREVIATION) {
            const name = target.name;
            if (action === "create") {
                message.push(`Abbreviation ${name} was added`);
            } else if (action === "update") {
                message.push(`Abbreviation ${name} was updated`);
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "Abbreviation",
                };
            } else if (action === "delete") {
                message.push(`Abbreviation ${name} was removed`);
            }
        } else if (target?.type === ActivityType.CITATION) {
            const name = target.name;
            if (action === "create") {
                message.push(`Reference ${name} was added`);
            } else if (action === "update") {
                message.push(`Reference ${name} was updated`);
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "Reference",
                };
            } else if (action === "delete") {
                message.push(`Reference ${name} was removed`);
            }
        } else if (target?.type === ActivityType.REPORT_DOCUMENT_COMMENT) {
            const name = target.name;
            if (action === "create") {
                message.push(`${name} added comment ${newValue?.text}`);
            } else if (action === "update") {
                message.push(`${name} updated comment`);
                moreDetails = {
                    edited: newValue,
                    initial: oldValue,
                    heading: "Report Document Comment",
                };
            } else if (action === "delete") {
                message.push(`${name} removed comment ${oldValue?.text}`);
            }
        } else if (target?.type === ActivityType.REPORT_DOCUMENT_REVIEW) {
            const name = target.name;
            if (keywords.includes("review-started")) {
                message.push(`${user.fullName} have started the review for ${name}`);
            } else if (keywords.includes("review-section")) {
                message.push(`${user.fullName} have reviewed section ${name}`);
            } else if (keywords.includes("review-approve")) {
                const stage = oldValue?.review?.stage?.title;
                message.push(
                    `${user.fullName} have approved the review stage ${stage} for ${name}`,
                );
            } else if (keywords.includes("review-stage-change")) {
                message.push(
                    `Review stage changed to ${newValue?.review?.stage?.title} for ${name}`,
                );
            } else if (keywords.includes("review-complete")) {
                message.push(`All reviews are completed for ${name}`);
            }
        }
        // End of Document

        let article, document;

        if (context !== ActivityType.Article && activity.article?._id) {
            article = activity?.article?.key + " - " + format.sanitize(activity.article?.name);
            message.push(article);
            if (moreDetails) {
                moreDetails.title =
                    activity?.article?.key + " - " + format.sanitize(activity.article?.name);
            }
        }
        if (context !== ActivityType.Document && activity.document?._id) {
            document = format.shorten(format.sanitize(activity.document?.name));
            message.push(`document: ${document}`);
            if (moreDetails) {
                moreDetails.title = activity.document?.name;
            }
        }

        // Global Logs - For future
        // let project;
        // if (context == ActivityType.Client) {
        //     project = format.shorten(activity.project?.name);
        //     message.push(`project: ${project}`);
        // }

        const description = message.length > 0 ? message.join(", ") : action;
        return {
            description: format.sanitize(description),
            moreDetails: moreDetails ?? undefined,
        };
    };

    const ProjectActivity = observer(({ activity }) => {
        const { description, moreDetails } = process(activity, context);

        return (
            <div className="details">
                <div className="data">
                    <span className="date-time">{format.datetime(activity.time)}</span>
                    <span
                        className="title"
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                    <div className="user">
                        <Avatar className="avatar" user={activity.user} size={60} />
                        <span className="name">{activity.user.fullName} </span>
                    </div>
                </div>
                <div className="footer">
                    {moreDetails && (
                        <MoreDetailsButton
                            setShow={setShow}
                            setCurrent={setCurrent}
                            activity={activity}
                            moreDetails={moreDetails}
                            setDetails={setDetails}
                        />
                    )}
                </div>
            </div>
        );
    });

    const Activities = observer(() => {
        return (
            <div className="activity-list">
                <div className="date">{date}</div>

                {activities.map((activity) => {
                    const { description, moreDetails } = process(activity, context);
                    return (
                        <div className="side-content" key={activity._id}>
                            <div className="details">
                                <span
                                    className="title"
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                                <span className="time">{activity.created}</span>
                            </div>
                            <div className="footer">
                                <div className="user">
                                    <Avatar className="avatar" user={activity.user} size={60} />
                                    <span className="name">{activity.user.fullName} </span>
                                </div>
                                {moreDetails && (
                                    <MoreDetailsButton
                                        setShow={setShow}
                                        setCurrent={setCurrent}
                                        activity={activity}
                                        moreDetails={moreDetails}
                                        setDetails={setDetails}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}

                {show && (
                    <Modal
                        show={show}
                        hideModal={hideModal}
                        activity={current}
                        moreDetails={details}
                    />
                )}
            </div>
        );
    });

    if (view === ActivityLogView.Main) {
        return (
            <div className="project-activity">
                {activities.map((activity) => {
                    return <ProjectActivity activity={activity} key={activity._id} />;
                })}
                {show && (
                    <Modal
                        show={show}
                        hideModal={hideModal}
                        activity={current}
                        moreDetails={details}
                    />
                )}
            </div>
        );
    }

    if (view === ActivityLogView.Sidebar) {
        return <Activities />;
    }
});

export default List;
