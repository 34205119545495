import { Plugin, PluginKey } from "@tiptap/pm/state";
import { ReportDocumentSection } from "@app/constants";

const handleTransactionStep = (step, oldState, newState, tr) => {
    step.getMap().forEach((oldStart, oldEnd, newStart, newEnd) => {
        const affectedMarksInOldState = oldState.doc.rangeHasMark(
            oldStart,
            oldEnd,
            oldState.schema.marks[ReportDocumentSection.HELP_TEXT.MARK_NAME],
        );
        const affectedMarksInNewState = newState.doc.rangeHasMark(
            newStart,
            newEnd,
            newState.schema.marks[ReportDocumentSection.HELP_TEXT.MARK_NAME],
        );

        if (affectedMarksInOldState || affectedMarksInNewState) {
            tr = tr.step(step.invert(oldState.doc));
        }
    });
    return tr;
};

export const preventEditingIfNotInTemplateMode = ({ editor }) =>
    new Plugin({
        key: new PluginKey("preventEditingIfNotInTemplateMode"),

        appendTransaction(transactions, oldState, newState) {
            const {
                view: {
                    _props: { templateMode },
                },
            } = editor;

            if (templateMode) {
                return;
            }

            let tr = newState.tr;

            for (let i = 0; i < transactions.length; i++) {
                const transaction = transactions[i];
                // eslint-disable-next-line no-loop-func
                transaction.steps.forEach((step) => {
                    tr = handleTransactionStep(step, oldState, newState, tr);
                });
            }

            if (tr.steps.length) {
                return tr;
            }
        },
    });

export default preventEditingIfNotInTemplateMode;
