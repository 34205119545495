import React, { useState } from "react";
import { observer } from "mobx-react";
import notify from "@app/components/notify/index";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";

import Comment from "./comment";
import Modal from "./modal";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { CommentStatus } from "@app/constants";

import "./style/comments.scoped.scss";

const ALL_TAB = "all";
const OPEN_TAB = "open";
const RESOLVED_TAB = "resolved";

const Comments = observer(({ state }) => {
    let activeTab = OPEN_TAB;
    const params = qs.parse(useLocation().search, { ignoreQueryPrefix: true });

    if (params?.resolvedcomments === "true") {
        activeTab = RESOLVED_TAB;
    } else if (params?.pendingcomments === "true") {
        activeTab = OPEN_TAB;
    }

    const [modalOpen, setModalOpen] = useState(false);
    const [activeKey, setActiveKey] = React.useState(activeTab);

    const switchTab = (key) => {
        setActiveKey(key);
    };

    /**
     * Save a comment
     */
    const save = async (data) => {
        try {
            await state.saveComment(data);
            return true;
        } catch (ex) {
            notify.error("Error saving the comment");
        }
    };

    /**
     * Delete a comment
     */
    const remove = async (comment) => {
        try {
            await state.removeComment(comment);
            return true;
        } catch (ex) {
            notify.error("Error removing the comment");
        }
    };

    /**
     * Resolve a comment
     */
    const resolve = async (data) => {
        try {
            await state.resolveComment(data);
            return true;
        } catch (ex) {
            notify.error("Error resolving the comment");
        }
    };

    /**
     * un resolve a comment
     */
    const unResolve = async (comment) => {
        try {
            await state.unResolveComment(comment);
            return true;
        } catch (ex) {
            notify.error("Error marking the comment as pending");
        }
    };

    const addReply = async (data) => {
        await state.addReply(data);
        return true;
    };

    const updateReply = async (data) => {
        await state.updateReply(data);
    };

    const removeReply = async (data) => {
        await state.removeReply(data);
    };

    const List = observer(({ comments }) => {
        const noComments = !state.loading && comments?.length === 0;

        return (
            <div className="list">
                {comments.map((comment) => {
                    return (
                        <Comment
                            comment={comment}
                            key={comment._id}
                            onRemove={() => remove(comment)}
                            onUpdate={(data) => save(data)}
                            onResolve={() => resolve(comment)}
                            onUnResolve={() => unResolve(comment)}
                            onAddReply={addReply}
                            onUpdateReply={updateReply}
                            onRemoveReply={removeReply}
                        />
                    );
                })}

                {noComments && (
                    <div className="empty">
                        <div className="icon">
                            <InfoCircleOutlined />
                        </div>
                        <div className="text">
                            There are no comments available. <br />
                            You can add a comment using the <b>Add Comment</b> button.
                        </div>
                    </div>
                )}
            </div>
        );
    });

    return (
        <div className="comments">
            <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} onSubmitHandler={save} />
            <div className="heading">
                <span className="title">Comments</span>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setModalOpen(true);
                    }}
                >
                    Add Comment
                </Button>
            </div>
            <Tabs activeKey={activeKey} onTabClick={switchTab}>
                <Tabs.TabPane tab="Open" key={OPEN_TAB}>
                    <List
                        comments={state.comments.filter(
                            (comment) => comment.status === CommentStatus.PENDING,
                        )}
                    />
                </Tabs.TabPane>

                <Tabs.TabPane tab="Resolved" key={RESOLVED_TAB}>
                    <List
                        comments={state.comments.filter(
                            (comment) => comment.status === CommentStatus.RESOLVED,
                        )}
                    />
                </Tabs.TabPane>

                <Tabs.TabPane tab="All" key={ALL_TAB}>
                    <List comments={state.comments} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
});

export default Comments;
